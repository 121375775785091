// Actions GROUP
export const UPDATE_DATA_CURRENT_GROUP = "UPDATE_DATA_CURRENT_GROUP";
export const CREATE_AUTORESPONSE_GROUP_REQUEST =
  "CREATE_AUTORESPONSE_GROUP_REQUEST";
export const CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS =
  "CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS";
export const GET_GROUP = "GET_GROUP";
export const SET_GROUPS = "SET_GROUPS";
export const CHANGE_GROUP = "CHANGE_GROUP";
export const UPDATE_CHAT_STATUS_LABEL = "UPDATE_CHAT_STATUS_LABEL";
export const UPDATE_SCHEDULE_AUTORESPONSE_GROUP = "UPDATE_SCHEDULE_AUTORESPONSE_GROUP";
export const SET_CHAT_STATUS_TAGS = 'SET_CHAT_STATUS_TAGS'; 
export const SET_MESSAGE_LIMIT = 'SET_MESSAGE_LIMIT'; 
export const TOGGLE_TEMPLATES_MANAGED_BY_ADMIN = 'TOGGLE_TEMPLATES_MANAGED_BY_ADMIN';
export const TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER = 'TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER';
export const TOGGLE_RESTRICTION_FOR_PUBLISHER = 'TOGGLE_RESTRICTION_FOR_PUBLISHER';
export const TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM = 'TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM';
export const SET_TAGS_LVL = 'SET_TAGS_LVL';
export const SET_LEVELS = 'SET_LEVELS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const REGISTER_GROUP_REQUEST = 'REGISTER_GROUP_REQUEST';
export const REGISTER_GROUP_SUCCESS = 'REGISTER_GROUP_SUCCESS';
export const REGISTER_GROUP_FAILURE = 'REGISTER_GROUP_FAILURE';
export const VALIDATE_GROUP_PHONE_REQUEST = 'VALIDATE_GROUP_PHONE_REQUEST';
export const VALIDATE_GROUP_PHONE_SUCCESS = 'VALIDATE_GROUP_PHONE_SUCCESS';
export const VALIDATE_GROUP_PHONE_FAILURE = 'VALIDATE_GROUP_PHONE_FAILURE';


// Constantes que necesitan ser enviadas al microfrontend wbp-dashboard
export const MF = {
  ADD_LEVEL: 'MF_ADD_LEVEL',
  ADD_TAG_LVL: 'MF_ADD_TAG_LVL',
  DELETE_LEVEL: 'MF_DELETE_LEVEL',
  DELETE_TAG_LVL: 'MF_DELETE_TAG_LVL',
  UPDATE_LEVEL_NAME: 'MF_UPDATE_LEVEL_NAME',
  UPDATE_TAG_NAME: 'MF_UPDATE_TAG_NAME',
}
