import { APIClient } from "../../helpers/apiClient";
import {
  UPDATE_DATA_CURRENT_GROUP,
  CREATE_AUTORESPONSE_GROUP_REQUEST,
  CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS,
  UPDATE_SCHEDULE_AUTORESPONSE_GROUP,
  GET_GROUP,
  SET_GROUPS,
  CHANGE_GROUP,
  UPDATE_CHAT_STATUS_LABEL,
  SET_CHAT_STATUS_TAGS,
  SET_MESSAGE_LIMIT,
  TOGGLE_TEMPLATES_MANAGED_BY_ADMIN,
  TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER,
  TOGGLE_RESTRICTION_FOR_PUBLISHER,
  SET_TAGS_LVL,
  SET_LEVELS,
  SET_LANGUAGE,
  TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM,
  REGISTER_GROUP_REQUEST,
  REGISTER_GROUP_SUCCESS,
  REGISTER_GROUP_FAILURE,
  VALIDATE_GROUP_PHONE_REQUEST,
  VALIDATE_GROUP_PHONE_SUCCESS,
  VALIDATE_GROUP_PHONE_FAILURE
} from "./constants";
import {
  setChats,
  setChatsPage,
  setTemplates,
  deleteChatCache,
  setChatFilter,
  setCurrentChat,
  resetChatSearch,
  setActiveContact,
  changeTagsFilterChats,
  setChatAgentFilter,
  changeTypeFilterChats,
  setTagContact,
  changeTagsLevelFilterChats,
  setErrorCodes,
} from "../actions";
import axios from "axios";
import { client } from "../../lib/prismic";
import { getNotifications } from "../notifications/actions";
import { loginUser } from '../auth/actions';

const apiClient = new APIClient();
const { update, get } = apiClient;

export const updateDataCurrentGroup = (payload) => ({
  type: UPDATE_DATA_CURRENT_GROUP,
  payload,
});

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

export const createAutoResponseGroup = (payload) => async (dispatch, getState) => {
  try {
    await update(`/groups/${payload.groupId}/auto-response`, payload.message);
    const currentGroupId = getState().Group.currentGroup?.id;
    if (currentGroupId === payload.groupId) {
      dispatch({
        type: CREATE_AUTORESPONSE_GROUP_REQUEST_SUCCESS,
        payload: payload.message,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getGroup = (group) => async (dispatch) => {
  const { groupId } = group;
  try {
    const group = await get(`/groups/${groupId}`);
    dispatch(updateDataCurrentGroup(group));
    if (group.language && (group.language === "es" || group.language === "pt" || group.language === "en")){
      dispatch(setLanguage(group.language));
    }
    else {
      dispatch(setLanguage("es"));
    }

    const errorCodes = await client.getByUID("error_codes", (group.language || "es"), { ref: null });
    dispatch(setErrorCodes(errorCodes?.data?.list || []));
  } catch (error) {
    console.log(error)
  }
};

export const setGroups = (groups) => ({
  type: SET_GROUPS,
  payload: groups,
});

export const changeGroup = (group) => async (dispatch, getState) => {
  const groupId = getState().Group.currentGroup?.id;
  if (group.groupId === groupId) return;
  dispatch(setChats([]));
  dispatch(setActiveContact(null));
  dispatch(setCurrentChat(null));
  dispatch(setChatFilter(""));
  dispatch(changeTagsFilterChats([]));
  dispatch(changeTagsLevelFilterChats([]));
  dispatch(setChatAgentFilter(null))
  dispatch(changeTypeFilterChats("default"));
  dispatch(setChatsPage(0));
  dispatch(setTemplates([]));
  dispatch(deleteChatCache());
  dispatch(resetChatSearch());
  dispatch(getGroup(group));
};

export const getGroupsByPhone = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groups = await get(`/groups/?phone=${currentGroup.phone}`);

    const currentPhoneGroup = getState().Group.currentGroup?.phone;
    if (currentPhoneGroup === currentGroup.phone) {
      let filteredGroups = groups.filter(i => i.id != currentGroup.id);
      return filteredGroups;
    } else return [];
  } catch (error) {
    console.log(error)
  }
};

export const saveChatStatusLabel = (payload) => async (dispatch, getState) => {
  try {
    await update(`/groups/${payload.groupId}/chat-status`, payload.label);

    const currentGroupId = getState().Group.currentGroup?.id;
    if (currentGroupId === payload.groupId) {
      dispatch({
        type: UPDATE_CHAT_STATUS_LABEL,
        payload: payload.label,
      });
    }
  } catch (error) {
    console.log(error)
  }
};

export const saveScheduleAutoResponseGroup = (payload) => async (dispatch, getState) => {
  try {
    const res = await update(`/groups/${payload.groupId}/schedule-auto-response`, payload.scheduleAutoResponse);

    const currentGroupId = getState().Group.currentGroup?.id;
    if (currentGroupId === payload.groupId) {
      dispatch({
        type: UPDATE_SCHEDULE_AUTORESPONSE_GROUP,
        payload: payload.scheduleAutoResponse,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveChatStatusTags = (chatStatusTags) => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    const res = await update(`/groups/${groupId}/chat-status-tags`, { chatStatusTags });

    const currentGroupId = getState().Group.currentGroup?.id;
    if (currentGroupId === groupId) {
      dispatch({
        type: SET_CHAT_STATUS_TAGS,
        payload: [...chatStatusTags],
      });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setMessageLimit = (limit) => ({
  type: SET_MESSAGE_LIMIT,
  payload: limit,
})

export const getMessageLimitData = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groupId = currentGroup.id;

    const data = await apiClient.get(`/groups/${groupId}/message-limit-data`);

    const newCurrentGroup = getState().Group.currentGroup;
    if (groupId == newCurrentGroup.id && data?.messageLimit) {
      dispatch(setMessageLimit(data.messageLimit));
    }
  } catch (error) {
    console.log(error);
  }
}

export const toggleTemplateManagedByAdmin = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groupId = currentGroup.id;
    const enabled = currentGroup.templatesManagedByAdmin;

    if (!enabled)
      await apiClient.put(`/groups/${groupId}/templates-managed-admin`);
    else
      await apiClient.remove(`/groups/${groupId}/templates-managed-admin`);

    const newCurrentGroup = getState().Group.currentGroup;
    if (groupId == newCurrentGroup.id) {
      dispatch({
        type: TOGGLE_TEMPLATES_MANAGED_BY_ADMIN,
        payload: !enabled,
      });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const toggleFreeContactsNotVisibleByPublisher = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groupId = currentGroup.id;
    const enabled = currentGroup.freeContactsNotVisibleByPublisher;

    if (!enabled)
      await apiClient.put(`/groups/${groupId}/free-contacts-not-visible-publisher`);
    else
      await apiClient.remove(`/groups/${groupId}/free-contacts-not-visible-publisher`);

    const newCurrentGroup = getState().Group.currentGroup;
    if (groupId == newCurrentGroup.id) {
      dispatch({
        type: TOGGLE_FREE_CONTACTS_NOT_VISIBLE_BY_PUBLISHER,
        payload: !enabled,
      });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const togglePublisherRestriction = (restrictionType) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groupId = currentGroup.id;
    let isEnabled = false;

    if (restrictionType == 'template') {
      isEnabled = !!currentGroup.templateRestrictionForPublisher;
      if (!isEnabled)
        await apiClient.put(`/groups/${groupId}/template-restriction-publisher`);
      else
        await apiClient.remove(`/groups/${groupId}/template-restriction-publisher`);
    } else if (restrictionType == 'campaign') {
      isEnabled = !!currentGroup.campaignRestrictionForPublisher;
      if (!isEnabled)
        await apiClient.put(`/groups/${groupId}/campaign-restriction-publisher`);
      else
        await apiClient.remove(`/groups/${groupId}/campaign-restriction-publisher`);
    } else if (restrictionType == 'flow') {
      isEnabled = !!currentGroup.flowRestrictionForPublisher;
      if (!isEnabled)
        await apiClient.put(`/groups/${groupId}/flow-restriction-publisher`);
      else
        await apiClient.remove(`/groups/${groupId}/flow-restriction-publisher`);
    } else {
      return;
    }

    const newCurrentGroup = getState().Group.currentGroup;
    if (groupId == newCurrentGroup.id) {
      dispatch({
        type: TOGGLE_RESTRICTION_FOR_PUBLISHER,
        payload: {
          restrictionField: (
            restrictionType == 'template' ?
              'templateRestrictionForPublisher' :
              (restrictionType == 'campaign' ? 'campaignRestrictionForPublisher' : 'flowRestrictionForPublisher')
          ),
          isEnabled: !isEnabled,
        },
      });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const toggleTeamRestrictionForUserOutTeam = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const groupId = currentGroup.id;
    const isEnabled = currentGroup.teamRestrictionForUserOutTeam;

    if (!isEnabled) await apiClient.put(`/groups/${groupId}/team-restriction-user-out`);
    else await apiClient.remove(`/groups/${groupId}/team-restriction-user-out`);

    const newCurrentGroup = getState().Group.currentGroup;
    if (groupId == newCurrentGroup.id) {
      dispatch({
        type: TOGGLE_TEAM_RESTRICTION_FOR_USER_OUT_TEAM,
        payload: !isEnabled,
      });
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const setTagsLvl = (tags) => ({
  type: SET_TAGS_LVL,
  payload: tags,
})

export const getTagsLvl = () => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    const res = await axios.get(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/tags`,
      {
        headers: { groupId: groupId },
      }
    );

    const afterGroupId = getState().Group.currentGroup?.id;
    if (groupId === afterGroupId) {
      dispatch(setTagsLvl(res))
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addTagLvl = (type, name) => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/tags`,
      {
        type: type,
        name: name
      },
      {
        headers: { groupId: groupId },
      }
    );

    const afterGroupId = getState().Group.currentGroup?.id;
    if (groupId === afterGroupId) {
      dispatch(getTagsLvl())
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteTagLvl = (id) => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    const tagLvl = getState().Contact.tagLvl;
    const response = await axios.delete(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/tags/${id}`);

    const afterGroupId = getState().Group.currentGroup?.id;
    if (groupId === afterGroupId) {
      if (Array.isArray(tagLvl?.tags) && tagLvl?.tags.length > 0){
        const newTags = tagLvl?.tags.filter(tag => tag.tagId !== response._id);
        const newTagLvl = tagLvl;
        newTagLvl.tags = newTags
        dispatch(setTagContact(newTagLvl))
      }
      dispatch(getTagsLvl())
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateTagName = (id, name) => async (dispatch, getState) => {
  try {
    await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/tags/${id}`,
      {
        name: name
      }
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setLevels = (levels) => ({
  type: SET_LEVELS,
  payload: levels,
})

export const getLevels = () => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    const res = await axios.get(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/levels`,
      {
        headers: { groupId: groupId },
      }
    );

    const afterGroupId = getState().Group.currentGroup?.id;
    if (groupId === afterGroupId) {
      dispatch(setLevels(res))
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addLevel = (name) => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/levels`,
      {
        name: name
      },
      {
        headers: { groupId: groupId },
      }
    );
    const afterGroupId = getState().Group.currentGroup?.id;
    if (groupId === afterGroupId) {
      dispatch(getLevels())
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateLevelName = (id, name) => async (dispatch, getState) => {
  try {
    await axios.post(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/levels/${id}`,
      {
        name: name
      }
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteLevel = (levelId) => async (dispatch, getState) => {
  try {
    await axios.delete(`${process.env.REACT_APP_WBPM_DATA_EXT_API_URL}/levels/${levelId}`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const registerGroup = (groupData) => async (dispatch, getState) => {    
  try {
    dispatch({ type: REGISTER_GROUP_REQUEST });
    // Asumiendo que el usuario ya está registrado y tenemos su ID
    const { groupName, groupPhone, language } = groupData;
    const user = getState().Auth.user;
    const payload = {
      name: groupName,
      phone: groupPhone,
      userId: user.userId,
      language: language
    };

    const response = await apiClient.post('/groups/genesys', payload);

    dispatch({
      type: REGISTER_GROUP_SUCCESS,
      payload: response.data
    });

    return response;
  } catch (error) {
    const errorMsg = error.response?.data?.message || 
                    error.message || 
                    "Error al registrar el grupo";

    dispatch({
      type: REGISTER_GROUP_FAILURE,
      payload: errorMsg
    });

    throw error;
  }
};

export const validateGroupPhone = (phone, countryCode) => async (dispatch, getState) => {
  try {
    dispatch({ type: VALIDATE_GROUP_PHONE_REQUEST });
    const payload = {
      phone: phone,
      countryCode: countryCode
    };
    const response = await apiClient.post('/groups/validate-phone', payload);

    dispatch({
      type: VALIDATE_GROUP_PHONE_SUCCESS,
      payload: response
    });

    return response;
  } catch (error) {
    const errorMsg = error.response?.data?.message || 
                    error.message || 
                    "Error al validar el número de teléfono";

    dispatch({
      type: VALIDATE_GROUP_PHONE_FAILURE,
      payload: errorMsg
    });

    throw error;
  }
}
