import { APIClient } from "../../helpers/apiClient";

import { SET_NOTIFICATIONS, SET_TOTAL_NOTIFICATIONS } from "./constants";

const apiClient = new APIClient();

export const setNotifications = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications,
  };
};

export const setTotalNotifications = (total) => {
  return {
    type: SET_TOTAL_NOTIFICATIONS,
    payload: total,
  };
};

export const getNotifications = (groupId) => async (dispatch, getState) => {
  try {
    const response = await apiClient.getFromMessageApi(`/notifications/user`,
      {
        headers: { GroupId: groupId },
      }
    );
    if (Array.isArray(response) && response.length > 0) {
      dispatch(setNotifications(response));
      dispatch(setTotalNotifications(response.filter(notification => !notification.isRead).length));
    } else dispatch(setNotifications([]));
  } catch (e) {
    console.log(e);
  }
};

export const readNotifications = (groupId) => async (dispatch, getState) => {
  try {
    const response = await apiClient.getFromMessageApi(`/notifications/read`,
      {
        headers: { GroupId: groupId },
      }
    );
    if(response)
      dispatch(setTotalNotifications(0));
  } catch (e) {
    console.log(e);
  }
};

export const newNotification = (payload) => async (dispatch, getState) => {
  try {
    const currentUserId = getState().Auth.user?._id;
    const notifications = getState().Notifications.notifications;
    const totalNotifications = getState().Notifications.totalNotifications;
    if (payload && payload.userId === currentUserId) {
      const newNotifications = [payload, ...notifications];
      dispatch(setNotifications(newNotifications));
      dispatch(setTotalNotifications(totalNotifications + 1));
    }
  } catch (error) {
    console.log(error);
    throw Error("Error registering new notification");
  }
};
