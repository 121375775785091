export const GET_USER_CHAT = "GET_USER_CHAT";
export const GET_CHAT_LOADING = "GET_CHAT_LOADING";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_FAILED = "GET_CHAT_FAILED";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const UPDATE_CHAT_CACHE = "UPDATE_CHAT_CACHE";
export const DELETE_CHAT_CACHE = "DELETE_CHAT_CACHE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_MESSAGE_GLOBAL = "SET_MESSAGE_GLOBAL";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const UPDATE_STATUS_MESSAGE = "UPDATE_STATUS_MESSAGE";
export const SET_FILE = "SET_FILE";
export const SET_FILES = "SET_FILES";
export const SET_PREUPLOADED_MEDIA = "SET_PREUPLOADED_MEDIA";
export const SET_QUICK_REPLY_MTEMPLATE = "SET_QUICK_REPLY_MTEMPLATE";
export const SET_LIST_MESSAGE_MTEMPLATE = "SET_LIST_MESSAGE_MTEMPLATE";
export const SET_SEND_IMAGES = "SET_SEND_IMAGES";
export const SET_SEND_FILE = "SET_SEND_FILE";
export const SET_UNSENT_IMAGES = "SET_UNSENT_IMAGES";
export const SET_MESSAGE_BOX_LOCK = "SET_MESSAGE_BOX_LOCK";
export const GET_CHATS = "GET_CHATS";
export const CHANGE_TYPE_FILTER_CHATS = "CHANGE_TYPE_FILTER_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_FAILED = "GET_CHATS_FAILED";
export const SET_CHATS_LIST = "SET_CHATS_LIST";
export const SET_PAGE_CHAT = "SET_PAGE_CHAT";
export const SET_HAS_MORE_ITEMS = "SET_HAS_MORE_ITEMS";
export const SET_CHAT_FILTER = "SET_CHAT_FILTER";
export const RESET_PENDING_MESSAGES = "RESET_PENDING_MESSAGES";
export const SET_COUNTER_PENDING_MESSAGES = "SET_COUNTER_PENDING_MESSAGES";
export const CHANGE_TAGS_FILTER_CHATS = "CHANGE_TAGS_FILTER_CHATS";
export const CHANGE_TAGS_LEVEL_FILTER_CHATS = "CHANGE_TAGS_LEVEL_FILTER_CHATS";
export const SET_CHAT_AGENT_FILTER = "SET_CHAT_AGENT_FILTER";
export const SET_MATCHED_MESSAGES = "SET_MATCHED_MESSAGES";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SEARCH_LOADING = "SET_SEARCH_LOADING";
export const SET_SEARCH_TIMELAPSE = "SET_SEARCH_TIMELAPSE";
export const RESET_SEARCH = "RESET_SEARCH";
export const SET_MESSAGE_POSITION_CHAT = "SET_MESSAGE_POSITION_CHAT";
export const OPEN_CONTACT_EDIT_MODAL = "OPEN_CONTACT_EDIT_MODAL";
export const SET_STICKERS = "SET_STICKERS";
