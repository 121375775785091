import {
  SET_CURRENT_CHAT,
  SET_MESSAGE,
  UPDATE_CHAT_CACHE,
  GET_CHAT_LOADING,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILED,
  DELETE_CHAT_CACHE,
  SET_FILE,
  SET_FILES,
  SET_PREUPLOADED_MEDIA,
  SET_QUICK_REPLY_MTEMPLATE,
  SET_LIST_MESSAGE_MTEMPLATE,
  SET_SEND_IMAGES,
  SET_SEND_FILE,
  SET_UNSENT_IMAGES,
  SET_MESSAGE_BOX_LOCK,
  GET_CHATS,
  GET_CHATS_SUCCESS,
  GET_CHATS_FAILED,
  SET_CHATS_LIST,
  SET_PAGE_CHAT,
  SET_HAS_MORE_ITEMS,
  SET_CHAT_FILTER,
  SET_COUNTER_PENDING_MESSAGES,
  SET_MESSAGE_GLOBAL,
  CHANGE_TYPE_FILTER_CHATS,
  CHANGE_TAGS_FILTER_CHATS,
  SET_CHAT_AGENT_FILTER,
  SET_MATCHED_MESSAGES,
  SET_SEARCH_PAGE,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOADING,
  SET_SEARCH_TIMELAPSE,
  RESET_SEARCH,
  SET_MESSAGE_POSITION_CHAT,
  OPEN_CONTACT_EDIT_MODAL,
  SET_STICKERS,
  CHANGE_TAGS_LEVEL_FILTER_CHATS,
} from "./constants";

const INIT_STATE = {
  currentChat: null,
  message: { text: "", media: "" },
  chatsCache: {},
  file: null,
  files: [],
  sendImages: 0,
  sendFile: false,
  unsentImages: 0,
  messageBoxLock: false,
  fileType: "",
  fileName: "",
  preuploadedMedia: null,
  quickReplyMTemplate: null,
  listMessageMTemplate: null,
  chatsList: [],
  loadingChats: false,
  page: 0,
  hasMoreItems: true,
  filter: "",
  counterPendingMessages: 0,
  typeFilter: "default",

  tagsLevelFilter: [],

  tagsFilter: [],
  agentFilter: null,

  matchedMessages: [],
  searchPage: 0,
  searchQuery: "",
  searchTimeLapse: "week",
  isSearchLoading: false,

  messagePositionChat: 1,
  openContactEditModal:false,

  stickers: []
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_TAGS_LEVEL_FILTER_CHATS:
      return {
        ...state,
        tagsLevelFilter: action.payload,
      };
    case CHANGE_TAGS_FILTER_CHATS:
      return {
        ...state,
        tagsFilter: action.payload,
      };
    case CHANGE_TYPE_FILTER_CHATS:
      return {
        ...state,
        typeFilter: action.payload,
      };
    case SET_COUNTER_PENDING_MESSAGES:
      return {
        ...state,
        counterPendingMessages: action.payload,
      };

    case GET_CHAT_LOADING:
      return {
        ...state,
        chatLoading: true,
      };
    case GET_CHAT_SUCCESS:
      return {
        ...state,
        chatLoading: false,
      };
    case GET_CHAT_FAILED:
      return {
        ...state,
        chatLoading: false,
      };

    case SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.payload,
      };

    case UPDATE_CHAT_CACHE:
      return {
        ...state,
        chatsCache: {
          ...state.chatsCache,
          [action.payload.contactId]: action.payload.messages,
        },
      };
    case DELETE_CHAT_CACHE:
      return {
        ...state,
        chatsCache: {},
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: { ...state.message, ...action.payload },
      };

    case SET_MESSAGE_GLOBAL:
      return {
        ...state,
        message: { ...state.message, text: action.payload },
      };
    case SET_FILE:
      return {
        ...state,
        file: action.payload.file,
        fileType: action.payload.type,
        fileName: action.payload.name,
      };
    case SET_FILES:
      return {
        ...state,
        files: action.payload
      }
    case SET_PREUPLOADED_MEDIA:
      return {
        ...state,
        preuploadedMedia: action.payload,
      }
    case SET_QUICK_REPLY_MTEMPLATE:
      return {
        ...state,
        quickReplyMTemplate: action.payload,
      }
    case SET_LIST_MESSAGE_MTEMPLATE:
      return {
        ...state,
        listMessageMTemplate: action.payload,
      }
    case SET_SEND_IMAGES:
      return {
        ...state,
        sendImages: action.payload,
      };
    case SET_SEND_FILE:
      return {
        ...state,
        sendFile: action.payload,
      };
    case SET_UNSENT_IMAGES:
      return {
        ...state,
        unsentImages: action.payload,
      };
    case SET_MESSAGE_BOX_LOCK:
      return {
        ...state,
        messageBoxLock: action.payload
      }
    case GET_CHATS:
      return {
        ...state,
        loadingChats: true,
      };
    case GET_CHATS_FAILED:
      return {
        ...state,
        loadingChats: false,
      };
    case GET_CHATS_SUCCESS: {
      return {
        ...state,
        loadingChats: false,
      };
    }
    case SET_CHATS_LIST: {
      return {
        ...state,
        chatsList: action.payload,
      };
    }
    case SET_PAGE_CHAT: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case SET_HAS_MORE_ITEMS: {
      return {
        ...state,
        hasMoreItems: action.payload,
      };
    }
    case SET_CHAT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case SET_CHAT_AGENT_FILTER: {
      return {
        ...state,
        agentFilter: action.payload,
      };
    }
    case SET_MATCHED_MESSAGES: {
      return {
        ...state,
        matchedMessages: action.payload,
      };
    }
    case SET_SEARCH_PAGE: {
      return {
        ...state,
        searchPage: action.payload,
      };
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }
    case SET_SEARCH_TIMELAPSE: {
      return {
        ...state,
        searchTimeLapse: action.payload,
      };
    }
    case SET_SEARCH_LOADING: {
      return {
        ...state,
        isSearchLoading: action.payload,
      };
    }
    case RESET_SEARCH: {
      return {
        ...state,
        matchedMessages: [],
        searchPage: 0,
        searchQuery: "",
        searchTimeLapse: "week",
        isSearchLoading: false,
      };
    }
    case SET_MESSAGE_POSITION_CHAT: {
      return {
        ...state,
        messagePositionChat: action.payload,
      }
    }
    case OPEN_CONTACT_EDIT_MODAL: {
      return {
        ...state,
        openContactEditModal: action.payload,
      }
    }
    case SET_STICKERS: {
      return {
        ...state,
        stickers: action.payload,
      }
    }
    default:
      return { ...state };
  }
};

export default Chat;
