import { APIClient, setAuthorization } from "../../helpers/apiClient";
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    FETCH_USER,
    FETCH_USER_SUCCESS,
  SET_PERMISSIONS_CURRENT_GROUP,
  SET_ENTER_KEY_SWITCH_CHECKED,
  SET_SERVICE_STATUS,
  SET_USER_SIDEBAR_DEFAULT_OPEN,
} from './constants';
import crypto from 'crypto';

import { setGroups, getGroup, updateMembers, setEventNameShowBlur } from "../actions";

const apiClient = new APIClient();
const create = apiClient.create;

export const loginUser = (email, password, history) => async (dispatch) => {
  try {
    if (!localStorage.getItem("authUser")) {
      dispatch({
        type: LOGIN_USER,
        payload: { email, password, history },
      });
      const response = await create("/login", { email, password });
      const { token } = response;

      setAuthorization(token);

      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("token", token);
      localStorage.setItem("alert", "unread");

      dispatch(loginUserSuccess(response));
      history.push("/dashboard");
      return true;
    } else {
      history.push("/dashboard");
    }
  } catch (error) {
    if (error === 'Invalid credentials') {
      dispatch(apiError('Datos de acceso inválidos'));
    } else {
      dispatch(apiError(error));
    }
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
  }
};

export const loginUserSuccess = (token) => ({
    type: LOGIN_USER_SUCCESS,
    payload: token
});

export const setServiceStatus = (status) => ({
  type: SET_SERVICE_STATUS,
  payload: status,
});

export const setServiceStatusAvailable = async (dispatch) => {
  try {
    const response = await apiClient.put("/users/me/service-status-available", {});
    dispatch(setServiceStatus(response.serviceStatus))
  } catch (error) {
    console.log("error", error.message);
  }
}

export const setServiceStatusBusy = async (dispatch) => {
  try {
    const response = await apiClient.put("/users/me/service-status-busy", {});
    dispatch(setServiceStatus(response.serviceStatus))
  } catch (error) {
    console.log("error", error.message);
  }
}

export const setServiceStatusUnavailable = async (dispatch) => {
  try {
    const response = await apiClient.put("/users/me/service-status-unavailable", {});
    dispatch(setServiceStatus(response.serviceStatus))
  } catch (error) {
    console.log("error", error.message);
  }
}

export const setPermissionsCurrentGroup = (permissions) => ({
  type: SET_PERMISSIONS_CURRENT_GROUP,
  payload: permissions,
});

export const registerUserGenesys = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_USER,
      payload: { userData }
    });

    const response = await create("/users/genesys", userData);

    const toStore = {
      userId: response.userId,
      email: response.email,
      password: userData.password
    }
    dispatch(registerUserGenesysSuccess(toStore));
    return response;

  } catch (error) {
    dispatch(apiError(error?.response?.data?.message || "Error en el registro"));
    throw error;
  }
};

export const registerUserGenesysSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
});

export const logoutUser = (history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_USER,
      payload: { history }
    });
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");

    try {
      await create("/logout", {});
    } catch (err) {
      console.log('Error calling API logout:', err);
    }

    window.location = "login";
  } catch (error) {
    console.log('Error trying logout:', error.message);
  }
};

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error
});

export const fetchUser = (token) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_USER,
      payload: { token }
    });
    const response = await create("/me", {});
    const { permissions, useEnterKeyToSend } = response; // permissions = groups
    // const useEnterKeyToSend = localStorage.getItem('useEnterKeyToSend') == "true" ? true : false;
    dispatch(setServiceStatus(response?.serviceStatus))
    if (useEnterKeyToSend) {
      dispatch({
        type: SET_ENTER_KEY_SWITCH_CHECKED,
        payload: !!useEnterKeyToSend,
      });
    }
    
    dispatch(fetchUserSuccess(response));
    dispatch(setGroups(permissions));

    if (permissions && permissions[0]) {
      let defaultGroup = permissions[0];
      const groupPhone = sessionStorage.getItem('urlQueryGroupPhone');
      if (groupPhone) {
        const foundGroup = permissions.find(p => p.groupPhone === groupPhone);
        if (foundGroup) {
          defaultGroup = foundGroup;
          // clear query param of group
          const queryParams = new URLSearchParams(window.location.search);
          queryParams.delete('group');
          const search = queryParams.toString().length ? `?${queryParams.toString()}` : '';
          window.history.replaceState(null, '', `${window.location.pathname}${search}`);
        } else {
          // clear all query params
          window.history.replaceState(null, '', window.location.pathname);
          sessionStorage.removeItem('urlQueryPhone');
        }
        sessionStorage.removeItem('urlQueryGroupPhone');
      }
      dispatch(setPermissionsCurrentGroup(defaultGroup));
      dispatch(getGroup(defaultGroup));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const fetchUserSuccess = (user) => ({
    type: FETCH_USER_SUCCESS,
    payload: { user }
})

export const setEnterKeySwitchChecked = (enterKeySwitchChecked) => async (dispatch) => {
  try {
    const response = await apiClient.update("/users/me", {
      useEnterKeyToSend: !!enterKeySwitchChecked,
    });
    // localStorage.setItem('useEnterKeyToSend', enterKeySwitchChecked);
    dispatch({
      type: SET_ENTER_KEY_SWITCH_CHECKED,
      payload: enterKeySwitchChecked
    });
  } catch (error) {
    console.log("error", error.message);
  }
};

export const userRoleChanged = (payload) => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;
    const members = getState().Members.members;
    const { userId, role } = payload;

    if (userId === user?._id) {
      dispatch(setEventNameShowBlur('user-role-changed'));
      localStorage.removeItem('token');
      setTimeout(() => {
        dispatch(logoutUser(null));
        dispatch(setEventNameShowBlur(''));
      }, 6000);
    } else {
      const _members = members?.map((item) => {
        if (item.id === userId) {
          item.role = role;
          if (role === 'ROLE_ADMI') delete item.tagId;
        }
        return item;
      });
      if (Array.isArray(_members) && _members.length > 0) dispatch(updateMembers(_members));
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const userCreated = (payload) => async (dispatch, getState) => {
  try {
    const members = getState().Members.members;
    const { userId, name, email, role, createdAt } = payload;
    const newUser = {
      id: userId,
      name,
      email,
      role,
      createdAt,
    };

    const newMembers = Array.isArray(members) ? [...members, newUser] : [newUser];

    dispatch(updateMembers(newMembers));
  } catch (error) {
    console.log(error.message);
  }
}

export const userDisabled = (payload) => async (dispatch, getState) => {
  try {
    const user = getState().Auth.user;
    const members = getState().Members.members;
    const { userId: incomingUserId } = payload;

    if (incomingUserId === user?._id) {
      dispatch(setEventNameShowBlur('user-disabled'));
      localStorage.removeItem('token');
      setTimeout(() => {
        dispatch(logoutUser(null));
        dispatch(setEventNameShowBlur(''));
      }, 6000);
    } else {
      const _members = members?.filter((member) => member.id !== incomingUserId);
      if (Array.isArray(_members) && _members.length > 0) dispatch(updateMembers(_members));
    }
  } catch (error) {
    console.log(error.message);
  }
}

export const resetPassword = (email) => async (dispatch) => {
  try {
    const response = await create('/password-recovery', { email });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const encryptPassword = (data, secretKey) => {
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(secretKey, "hex"),
    Buffer.alloc(16)
  );
  const encrypted = cipher.update(data, "utf8", "base64");
  const finalDecrypted = encrypted + cipher.final("base64");
  return finalDecrypted;
}

export const changePassword = (currentPassword, newPassword) => async () => {
  try {
    const secretKey = process.env.REACT_APP_PASSWORD_HASH_SECRET;
    const encryptedCurrentPassword = encryptPassword(currentPassword, secretKey);
    const encryptedNewPassword = encryptPassword(newPassword, secretKey);
    await apiClient.patch("/users/change-password", {
      currentPassword: encryptedCurrentPassword,
      newPassword: encryptedNewPassword,
    });
  } catch (error) {
    console.error("[auth][changePassword] Error:", error);
    throw error;
  }
};

export const loginFromOnboarding = (email, password, history) => async (dispatch) => {
  try {
    // Removed authUser and token from localStorage
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");

    if (!localStorage.getItem("authUser")) {
      dispatch({
        type: LOGIN_USER,
        payload: { email, password, history },
      });
      const response = await create("/login", { email, password });
      const { token } = response;

      setAuthorization(token);

      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("token", token);
      localStorage.setItem("alert", "unread");

      dispatch(loginUserSuccess(response));
      history.push("/dashboard/settings");
      return true;
    } else {
      history.push("/dashboard/settings");
    }
  } catch (error) {
    if (error === 'Invalid credentials') {
      dispatch(apiError('Datos de acceso inválidos'));
    } else {
      dispatch(apiError(error));
    }
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
  }
};

export const setUserSidebarDefaultOpen = (isOpen) => ({
  type: SET_USER_SIDEBAR_DEFAULT_OPEN,
  payload: isOpen,
});
