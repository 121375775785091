import { APIClient } from "../../helpers/apiClient";
// ASSETS
import SoundNotificationWA from "../../assets/media/notification_whatsapp.mp3";
import IconMantra from "../../assets/images/icon-mantra2.png";

import {
  GET_USER_CHAT,
  GET_CHAT_LOADING,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILED,
  SET_CURRENT_CHAT,
  SEND_MESSAGE,
  SET_MESSAGE,
  UPDATE_CHAT_CACHE,
  MESSAGE_RECEIVED,
  DELETE_CHAT_CACHE,
  SET_FILE,
  SET_FILES,
  SET_PREUPLOADED_MEDIA,
  SET_QUICK_REPLY_MTEMPLATE,
  SET_LIST_MESSAGE_MTEMPLATE,
  SET_SEND_IMAGES,
  SET_SEND_FILE,
  SET_UNSENT_IMAGES,
  GET_CHATS,
  GET_CHATS_SUCCESS,
  GET_CHATS_FAILED,
  SET_CHATS_LIST,
  SET_PAGE_CHAT,
  SET_HAS_MORE_ITEMS,
  SET_CHAT_FILTER,
  RESET_PENDING_MESSAGES,
  UPDATE_STATUS_MESSAGE,
  SET_COUNTER_PENDING_MESSAGES,
  SET_MESSAGE_GLOBAL,
  CHANGE_TYPE_FILTER_CHATS,
  CHANGE_TAGS_FILTER_CHATS,
  SET_MESSAGE_BOX_LOCK,
  SET_CHAT_AGENT_FILTER,
  SET_MATCHED_MESSAGES,
  SET_SEARCH_PAGE,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOADING,
  SET_SEARCH_TIMELAPSE,
  RESET_SEARCH,
  SET_MESSAGE_POSITION_CHAT,
  OPEN_CONTACT_EDIT_MODAL,
  SET_STICKERS,
  CHANGE_TAGS_LEVEL_FILTER_CHATS,
} from "./constants";

import { setActiveContact } from "../contact/actions";
import { updateMembers } from "../members/actions";

import { scrollChatDown } from "../../helpers/chat";
import { LOGIN_USER } from "../auth/constants";
import { isIOS } from "../../helpers/checkBrowser";

import { setCounterPending, getCounter } from "../actions";

const apiClient = new APIClient();
const { get, create, patch, put, remove, getFromMessageApi, deleteToMessageApi } = apiClient;

export const changeTagsFilterChats = (tagsArray) => ({
  type: CHANGE_TAGS_FILTER_CHATS,
  payload: tagsArray,
});

export const changeTagsLevelFilterChats = (tagsArray) => ({
  type: CHANGE_TAGS_LEVEL_FILTER_CHATS,
  payload: tagsArray,
});

export const openContactEditModal = (value) => ({
  type: OPEN_CONTACT_EDIT_MODAL,
  payload: value,
});

export const changeTypeFilterChats = (filter) => ({
  type: CHANGE_TYPE_FILTER_CHATS,
  payload: filter,
});

export const setCounterPendingMessages = (counter) => ({
  type: SET_COUNTER_PENDING_MESSAGES,
  payload: counter,
})

export const resetPendingMessages = (contact) => async (dispatch, getState) => {
  try {
    const { id, content, sender, updatedAt, chat } = contact;
    const pendingMessages = chat ? chat.pendingMessages : 0;
    const { chatsList } = getState().Chat;
    let findIndexChat = null;
    chatsList.forEach((chat, index) => {
      if (chat._id === id) {
        findIndexChat = index;
      }
    });
    let newChatList = chatsList;
    const prevChat = newChatList[findIndexChat].chat;
    if (prevChat && prevChat.pendingMessages > 0) {
      newChatList[findIndexChat].chat = {
        ...prevChat,
        pendingMessages: 0,
      };
      dispatch(setChats(newChatList));
    }

    if (pendingMessages > 0) {
      await patch(`/contacts/${id}/chat`, {
        pendingMessages: 0,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export const openUserChatInSearchList = (payload) => async (dispatch, getState) => {
  const currentGroup = getState().Group.currentGroup;
  const { contactId, messageId } = payload;

  try {
    if (currentGroup) {
      dispatch(getChatLoading());

      const searchResultMessages = await apiClient.getFromMessageApi(`/messages/${contactId}?messageId=${messageId}`, {
        headers: { GroupId: currentGroup?.id }
      });

      if (Array.isArray(searchResultMessages) && searchResultMessages.length > 0) {
        dispatch(setMessagePositionInChat(searchResultMessages[searchResultMessages.length - 1].positionInChat));
        dispatch(setCurrentChat(searchResultMessages));
      }

      dispatch(getChatSuccess());
      scrollChatDown();
    }
  } catch (error) {
    dispatch(getChatSuccess());
  }
};

export const getUserChat = (contact) => async (dispatch, getState) => {
  const { id: contactId } = contact;
  const { chatsCache, chatsList } = getState().Chat;
  const currentGroup = getState().Group.currentGroup;
  dispatch(setCurrentChat([]));
  dispatch(setMessagePositionInChat(1));

  try {
    const chat = chatsCache[contactId];
    if (chat) {
      //set current chat
      dispatch(setCurrentChat(chat));
    } else {
      dispatch(getChatLoading());
      if (currentGroup) {
        const { id: groupId } = currentGroup;
        const response = await apiClient.getFromMessageApi(`/messages/${contactId}`, {
          headers: { GroupId: groupId },
        });
        const activeContact = getState().Contact.activeContact;
        dispatch(updateChatCache({ contactId, messages: response }));
        if (activeContact?._id === contactId) dispatch(setCurrentChat(response));
        // update read message
        let chatIndex = null;
        const contact = chatsList.find((contact, index) => {
          if (contact.id === contactId) {
            chatIndex = index;
            return true;
          } else {
            return false;
          }
        });
      }
    }
    dispatch(getChatSuccess());
    scrollChatDown();
  } catch (error) {
    dispatch(getChatSuccess());
  }
};

export const getChatLoading = () => ({
  type: GET_CHAT_LOADING,
});

export const getChatSuccess = () => ({
  type: GET_CHAT_SUCCESS,
});

export const getChatFailed = () => ({
  type: GET_CHAT_FAILED,
});

export const setCurrentChat = (chat) => ({
  type: SET_CURRENT_CHAT,
  payload: chat,
});

export const updateChatCache = (chat) => {
  return {
    type: UPDATE_CHAT_CACHE,
    payload: chat,
  };
};

export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
};

export const setMessageGlobal = (message) => {
  return {
    type: SET_MESSAGE_GLOBAL,
    payload: message,
  };
};

export const sendMessage = () => async (dispatch, getState) => {
  const { message, file, files, fileName, fileType, preuploadedMedia, quickReplyMTemplate, listMessageMTemplate } = getState().Chat;
  let counterSendImages = 0;
  try {
    const { currentGroup } = getState().Group;
    const activeContact = getState().Contact.activeContact;
    const { id: groupId } = currentGroup;

    if (listMessageMTemplate) {
      await apiClient.postToMessageApi("/messages", {
        contactId: activeContact.id,
        content: {
          type: 'list',
          title: listMessageMTemplate.title,
          text: listMessageMTemplate.text,
          options: listMessageMTemplate.options,
        }
      }, {
        headers: { GroupId: groupId },
      });
      return;
    }

    if (quickReplyMTemplate) {
      let data = {
        contactId: activeContact.id,
        content: {
          type: 'quick_reply',
          text: quickReplyMTemplate.text,
          contentType: quickReplyMTemplate.contentType,
          options: quickReplyMTemplate.options,
        }
      };
      if (quickReplyMTemplate.contentType != 'text') data.content.contentMedia = quickReplyMTemplate.contentMedia;
      if (quickReplyMTemplate.contentType == 'file') data.content.filename = quickReplyMTemplate.filename;
      await apiClient.postToMessageApi("/messages", data, {
        headers: { GroupId: groupId }
      });
      return;
    }

    if (preuploadedMedia) {
      await apiClient.postToMessageApi("/messages", {
        contactId: activeContact.id,
        content: {
          text: (preuploadedMedia.type == 'file' ? preuploadedMedia.filename : message.text),
          media: preuploadedMedia.url,
          type: preuploadedMedia.type
        }
      }, {
        headers: { GroupId: groupId },
      });
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      if (fileType === "file") formData.append("text", fileName);
      const text = fileType === "file" ? fileName : message.text;

      dispatch(setSendFile(true));
      const media = await apiClient.postToMessageApi("/storage/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          GroupId: groupId,
        },
      });

      await apiClient.postToMessageApi("/messages", {
        contactId: activeContact.id,
        content: { text, media: media.url, type: fileType },
      }, {
        headers: { GroupId: groupId },
      });
      dispatch(setSendFile(false));
    } else if (files?.length > 0) {
      let formData = null;
      dispatch(setMessageBoxLock({ status: true }));
      for (const file of files) {
        counterSendImages++;
        dispatch(setSendImages({ value: counterSendImages }));
        dispatch(setMessageBoxLock({ status: true }));
        formData = new FormData();
        formData.append("file", file);

        const media = await apiClient.postToMessageApi("/storage/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            GroupId: groupId,
          },
        });

        await apiClient.postToMessageApi("/messages", {
          contactId: activeContact.id,
          content: { text: file?.text ?? '', media: media.url, type: "image" },
        }, {
          headers: { GroupId: groupId },
        });
        formData = null;
      }
      dispatch(setSendImages({ value: 0 }));
      dispatch(setMessageBoxLock({ status: false }));
    } else {
      await apiClient.postToMessageApi("/messages", {
        contactId: activeContact.id,
        content: { type: "text", text: message.text },
      }, {
        headers: { GroupId: groupId },
      })
    }
  } catch (e) {
    console.log("error:", e);
    if (file) dispatch(setSendFile(false));
    if (files.length > 0) {
      let sentImages = counterSendImages - 1;
      let unsentImages = files.length - sentImages;
      dispatch(setUnsentImages({ value: unsentImages }));
      dispatch(setMessageBoxLock({ status: false }));
      dispatch(setSendImages({ value: 0 }));
    }
    if (e === 'PAYLOAD_TOO_LARGE')
      alert('Archivo demasiado grande');
    else if (e === 'UNSUPPORTED_MEDIA_TYPE')
      alert('Tipo de Archivo No Soportado');
  }
};

export const messageReceived = (message) => async (dispatch, getState) => {
  try {
    const { contactId, content, sender, updatedAt, sentByUserId, autoResponse } = message;
    const currentGroup = getState().Group.currentGroup;
    const { id: groupId } = currentGroup;
    const { chatsCache, currentChat, chatsList, messagePositionChat } = getState().Chat;

    const activeContact = getState().Contact.activeContact;
    const typeFilterChat = getState().Chat.typeFilter;

    const counterPendingMessages = getState().Counter.counterPending;

    if (typeFilterChat === "archived") {
      const foundChat = chatsList.find((chat) => chat._id === contactId);
      if (foundChat) {
        dispatch(getChats(true))
        return;
      };
    }

    if (activeContact?._id === contactId) {
      if (messagePositionChat !== 1) // search mode only
        dispatch(setMessagePositionInChat(messagePositionChat + 1));
      else
        dispatch(setCurrentChat([...currentChat, message])); // mensajes en user chat
    }
    const cachedChat = chatsCache[contactId];
    if (Array.isArray(cachedChat) && cachedChat.length > 0) {
      dispatch(updateChatCache({ contactId, messages: [...cachedChat, message] }));
    }

    const findContact = chatsList.find((contact) => contact.id === contactId);
    const listRestContacts = chatsList.filter(
      (contact) => contact.id !== contactId
    );

    if (findContact) {
      if (sender === "contact") {
        findContact.lastContactResponseAt = new Date();
      }
      const { chat: contactChat } = findContact;
      const firstContact = {
        ...findContact,
        chat: {
          lastMessage: content.text || content.media,
          isMedia: !!content.media,
          lastSender: sender,
          updateAt: updatedAt,
          pendingMessages:
            sender === "contact" && contactId !== activeContact?._id
              ? (!isNaN(contactChat?.pendingMessages) ? contactChat.pendingMessages + 1 : 1)
              : 0,
          lastMessageType: content.type,
          lastSentUserId: sender == 'group' && sentByUserId ? sentByUserId : (contactChat?.lastSentUserId),
          lastMessageAutoResponse: !!autoResponse,
          lastMessageId: message._id,
          lastMessageStatus: message.status,
        },
      };
      if(firstContact.chat.pendingMessages === 1){
        dispatch(setCounterPending(counterPendingMessages+1));
      }
      dispatch(
        setChats([
          firstContact,
          ...listRestContacts,
        ])
      );

      if (contactId === activeContact?._id) {
        dispatch(setActiveContact({
          ...firstContact,
        }));
        await patch(`/contacts/${contactId}/chat`, {
          pendingMessages: 0,
        });
      }

      if (
        contactId !== activeContact?._id ||
        document.visibilityState == "hidden"
      ) {
        if (isIOS()) return;
        if (Notification.permission !== "granted") {
          Notification.requestPermission();
        } else {
          showNotification(findContact, message, typeFilterChat, dispatch);
        }
      }
    } else {
      const { user, permissionsCurrentGroup } = getState().Auth;
      if (permissionsCurrentGroup.role === 'ROLE_PUBLISHER') {
        if (message.userId) {
          if (message.userId != user.id) return;
        } else if (message.userTagId) {
          if (message.userTagId !== permissionsCurrentGroup?.tagId) return;
        } else {
          if (!!currentGroup.freeContactsNotVisibleByPublisher) return;
        }
      }

      const searchFilter = getState().Chat.filter;
      const agentFilter = getState().Chat.agentFilter;
      const tagsFilter = getState().Chat.tagsFilter;
      const membersInGroup = getState().Members.members;

      const contact = await apiClient.get(`/contacts/${contactId}`);

      if (!contact) return;

      const userPermission = Array.isArray(user.permissions) && user.permissions.find(p => p.groupId === groupId);
      const userRole = (userPermission && userPermission.role) || null;
      if (!userRole) {
        return;
      } else if (!['ROLE_ADMI', 'ROLE_PUBLISHER'].includes(userRole)) {
        return;
      } else if (userRole === 'ROLE_PUBLISHER' && contact.userId && contact.userId !== user.id) {
        return;
      } else if (userRole === 'ROLE_ADMI' && contact.userTagId && !membersInGroup.find(m => m.tagId === contact.userTagId)) {
        return;
      } else if (userRole === 'ROLE_ADMI' && !contact.userTagId && userPermission.teamId) {
        const team = await apiClient.get(`/v2/teams/${userPermission.teamId}`);
        if (team && team.freeContactsNotVisibleByManager) return;
      }

      /* Check if any filter is active */
      if (searchFilter) {
        if (!(contact.name.toLowerCase().search(searchFilter.toLowerCase()) !== -1 || contact.phone.search(searchFilter) !== -1)) return;
      }
      if (Array.isArray(tagsFilter) && tagsFilter.length > 0) {
        const checkTagsInContact = tagsFilter.map((tag) => {
          return contact.tagIdList.includes(tag.value)
        });
        if (!checkTagsInContact.every((check) => check)) return;
      }
      if (agentFilter) {
        if (contact.userTagId) {
          let member = membersInGroup.find((i) => i.tagId === contact.userTagId);
          if (agentFilter.value !== member?.id || agentFilter.value === 'unassigned') return;
        } else {
          return;
        }
      }

      if (message.sender === typeFilterChat || ['default', 'important', 'finished'].includes(typeFilterChat)) {
        if (activeContact?._id === contactId && !activeContact.chat) {
          dispatch(setActiveContact({ ...activeContact, chat: contact.chat }));
        }
        if (chatsList.length % 20 === 0) chatsList.pop();
        const newChatList = [contact, ...chatsList];
        dispatch(setChats(newChatList));
        if (
          contactId !== activeContact?._id ||
          document.visibilityState == "hidden"
        ) {
          if (isIOS()) return;
          if (Notification.permission !== "granted") {
            Notification.requestPermission();
          } else {
            showNotification(contact, message, typeFilterChat, dispatch);
          }
        }
        if(contact.chat.pendingMessages === 1){
          dispatch(setCounterPending(counterPendingMessages+1));
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateStatusMessage = (status) => async (dispatch, getState) => {
  try {
    const { chatsCache, currentChat, chatsList } = getState().Chat;

    let copyChatsCache = chatsCache;
    if (copyChatsCache[status.contactId]) {
      let chatCacheIndex = null;
      copyChatsCache[status.contactId].forEach((chat, key) => {
        if (chat._id === status.id) {
          chatCacheIndex = key;
        }
      });
      copyChatsCache[status.contactId][chatCacheIndex].status = status.status;
      if (status.updatedAt) copyChatsCache[status.contactId][chatCacheIndex].updatedAt = status.updatedAt;
      if (status.status === "failed" && status.payloadFailed) copyChatsCache[status.contactId][chatCacheIndex].payloadFailed = status.payloadFailed;
      dispatch(
        updateChatCache({
          contactId: status.contactId,
          messages: copyChatsCache[status.contactId],
        })
      );
    }

    const activeContact = getState().Contact.activeContact;
    if (activeContact.id === status.contactId) {
      let copyCurrentChat = [...currentChat];
      let chatCacheIndex = null;
      copyCurrentChat.forEach((chat, key) => {
        if (chat._id === status.id) {
          chatCacheIndex = key;
        }
      });

      copyCurrentChat[chatCacheIndex].status = status.status;
      if (status.updatedAt) copyCurrentChat[chatCacheIndex].updatedAt = status.updatedAt;
      if (status.status === "failed" && status.payloadFailed) copyCurrentChat[chatCacheIndex].payloadFailed = status.payloadFailed;
      dispatch(setCurrentChat(copyCurrentChat));
    } else {
      // console.log("NO --- VIENDO CHAT DE USUARIO");
    }

    if (status.status === "failed") {
      const foundChat = chatsList.find((chat) => chat._id === status.contactId);
      if (foundChat?.chat?.lastMessageId === status.id) {
        foundChat.chat.lastMessageStatus = "failed";
        dispatch(setChats([...chatsList]));
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteChatCache = () => {
  return {
    type: DELETE_CHAT_CACHE,
  };
};

export const setFile = ({ file, type, name }) => {
  return {
    type: SET_FILE,
    payload: { file, type, name },
  };
};

export const setFiles = (files) => {
  return {
    type: SET_FILES,
    payload: files,
  };
};

export const setChatAsArchived = (contact) => async (dispatch, getState) => {
  try {
    const response = await put(`/contacts/${contact._id}/chat-archived`);

    if (response?.chatArchived) {
      dispatch(getChats(true));
    };
  } catch (error) {
    console.log(error);
  }
};

export const setChatAsUnarchived = (contact) => async (dispatch, getState) => {
  try {
    const response = await remove(`/contacts/${contact._id}/chat-archived`);
    if (response?.chatArchived === false) {
      dispatch(getChats(true));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteChat = (contact) => async (dispatch, getState) => {
  try {
    const response = await remove(`/contacts/${contact._id}/delete-chat`);
    if (response?._id) {
      const chatsList = getState().Chat.chatsList;
      const chatsCache = getState().Chat.chatsCache;

      const foundChat = chatsList?.find((chat) => chat._id === response._id);
      if (foundChat) dispatch(getChats(true));

      const foundCachedChat = chatsCache[response._id];
      if (foundCachedChat) {
        dispatch(updateChatCache({
          contactId: response._id,
          messages: undefined,
        }));
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getChats = (
  init = true,
  disableFirstContactAsActive = false,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CHATS });
    const currentGroup = getState().Group.currentGroup;
    var page = 0;
    if (!init) page = getState().Chat.page;
    else {
      dispatch(setChats([]));
      dispatch(setChatsPage(0));
      dispatch(setCounterPending(0));
    }
    const limit = 20
    const offset = page * limit;
    const chatsList = getState().Chat.chatsList;

    const agentFilter = getState().Chat.agentFilter;
    const searchFilter = getState().Chat.filter;
    const tagsFilter = getState().Chat.tagsFilter;
    const tagsLevelFilter = getState().Chat.tagsLevelFilter;
    const typeFilter = getState().Chat.typeFilter;
    const membersOfGroup = getState().Members.members;

    if (currentGroup) {
      const { id: groupId } = currentGroup;
      const m = membersOfGroup?.find(m => m.id == agentFilter?.value);
      const userTagId = m ? m.tagId : agentFilter?.value === 'unassigned' ? 'unassigned' : null;
      let params = {};
      params.groupId = groupId;
      params.offset = offset;
      params.limit = limit;
      if (searchFilter && searchFilter.length >= 3) params.filter = searchFilter;
      if (typeFilter && typeFilter !== "default" && typeFilter !== "archived" && typeFilter !== "important" && typeFilter !== "finished") params.lastsender = typeFilter;
      if (Array.isArray(tagsFilter) && tagsFilter.length > 0) params.tagIdList = tagsFilter.map(t => t.value).join(",");
      if (Array.isArray(tagsLevelFilter) && tagsLevelFilter.length > 0) params.filterTagsLvl = tagsLevelFilter.map(t => t.value).join(",");
      if (userTagId) params.filterTagId = userTagId;
      if (typeFilter === 'archived') params.archived = true;
      else if (typeFilter === 'important') params.important = true;
      else if (typeFilter === 'finished') params.finished = true;

      /*const chats = await axios.get(`${process.env.REACT_APP_WBPM_MESSAGE_ENDPOINT}/contacts/lastchats`, {
        params,
        headers: { GroupId: groupId, Authorization: `Bearer ${token}` },
      });*/

      const chats = await apiClient.getFromMessageApi(`/contacts/lastchats`, {
        params,
        headers: { GroupId: groupId }
      });

      for (var i=0; i<chats.length; i++){
        chats[i].id = chats[i]._id
      }

      const afterCurrentGroup = getState().Group.currentGroup;
      const { id: afterGroupId } = afterCurrentGroup;
      
      if (groupId === afterGroupId) {
        if (init && !disableFirstContactAsActive) {
          if (chats[0]) {
            const { id, chat } = chats[0];
            const pendingMessages = chat ? chat.pendingMessages : 0;

            if (pendingMessages > 0) {
              await patch(`/contacts/${id}/chat`, {
                pendingMessages: 0,
              });
              const newChatList = [...chats];
              const chatIdx = newChatList.findIndex(c => c.id == id);
              if (chatIdx != -1 && newChatList[chatIdx].chat?.pendingMessages > 0) {
                newChatList[chatIdx].chat.pendingMessages = 0;
                dispatch(setChats(newChatList));
              }
            }
          }
        }

        if(init){
          dispatch(getCounter(groupId, params.filter, params.tagIdList, params.filterTagId, params.filterTagsLvl, typeFilter));
        }

        const moreItems = chats.length ? true : false;
        const newChatsList = offset ? [...chatsList, ...chats] : chats;
        if (chats.length) {
          dispatch(setChats(newChatsList));
          if (init && !disableFirstContactAsActive) {
            dispatch(setChatsPage(1));
            dispatch(setActiveContact(chats[0]));
            dispatch(getUserChat(chats[0]));
          } else {
            dispatch(setChatsPage(page + 1));
          }
        }
        dispatch(setHasMoreItems(moreItems));
      }
    }

    dispatch(getChatsSuccess());
  } catch (error) {
    dispatch(getChatsFailed());
  }
};

export const getChatsFailed = () => ({
  type: GET_CHATS_FAILED,
});

export const getChatsSuccess = () => ({
  type: GET_CHATS_SUCCESS,
});

export const setChats = (contacts) => ({
  type: SET_CHATS_LIST,
  payload: contacts,
});

export const setChatsPage = (page) => ({
  type: SET_PAGE_CHAT,
  payload: page,
});

export const setHasMoreItems = (hasMoreItems) => ({
  type: SET_HAS_MORE_ITEMS,
  payload: hasMoreItems,
});

export const setChatFilter = (filter) => ({
  type: SET_CHAT_FILTER,
  payload: filter,
})

export const refreshContactChats = (contact) => async (dispatch, getState) => {
  const { user, permissionsCurrentGroup } = getState().Auth;
  const { chatsList } = getState().Chat;
  const { activeContact } = getState().Contact;
  let chatContactAssigned = chatsList.find(chat => chat.id === contact.id);
  if (permissionsCurrentGroup && permissionsCurrentGroup.role == 'ROLE_ADMI' && contact.userId) {
    const membersOfGroup = getState().Members.members;
    const memberIdx = membersOfGroup.findIndex(member => member.id == contact.userId);
    if (memberIdx === -1) {
      if (chatContactAssigned) dispatch(getChats());
      return;
    } else if (membersOfGroup[memberIdx].role !== 'ROLE_PUBLISHER') return;

    if (!membersOfGroup[memberIdx].tagId) {
      const updatedMembers = [...membersOfGroup];
      updatedMembers[memberIdx].tagId = contact.userTagId;
      dispatch(updateMembers(updatedMembers));
    }

    if (activeContact?._id === contact.id) {
      const newActiveContact = { ...activeContact, userTagId: contact.userTagId };
      dispatch(setActiveContact(newActiveContact));
    }
    if (chatContactAssigned) {
      const newArrayChatsList = chatsList.map((chat) => {
        if (chat.id === chatContactAssigned.id) return { ...chatContactAssigned, userTagId: contact.userTagId };
        return chat;
      });
      dispatch(setChats(newArrayChatsList));
    }
    return;
  }
  if (user.id != contact.userId) {
    if (chatContactAssigned) {
      const activeContactWasAssigned = activeContact?._id === contact.id;
      dispatch(
        getChats(
          true,
          !activeContactWasAssigned,
        )
      );
      if (activeContactWasAssigned) {
        dispatch(setMessage({ text: "", media: "" }));
        if (isIOS()) return;
        let notification = new Notification('Mantra', {
          icon: IconMantra,
          body: "Contacto asignado a otro agente",
        });
        notification.onclick = function (e) {
          e.preventDefault();
          window.focus();
          notification.close();
        };
      }
    }
  } else {
    if (!isIOS()) {
      let notification = new Notification('Mantra', {
        icon: IconMantra,
        body: "Nuevo contacto asignado",
      });
      notification.onclick = function (e) {
        e.preventDefault();
        window.focus();
        notification.close();
      };
    }
    if (permissionsCurrentGroup && !permissionsCurrentGroup.tagId) {
      const membersOfGroup = getState().Members.members;
      const i = membersOfGroup.findIndex(member => member.id == user.id);
      const updatedMember = [...membersOfGroup];
      updatedMember[i].tagId = contact.userTagId;
      dispatch(updateMembers(updatedMember));
    }
    const activeContact = getState().Contact.activeContact;
    if (activeContact?._id === contact.id) {
      const newActiveContact = { ...activeContact, userTagId: contact.userTagId };
      dispatch(setActiveContact(newActiveContact));
    }
    if (chatContactAssigned) {
      const newArrayChatsList = chatsList.map((chat) => {
        if (chat.id === chatContactAssigned.id) return { ...chatContactAssigned, userTagId: contact.userTagId };
        return chat;
      });
      dispatch(setChats(newArrayChatsList));

      dispatch(changeStatusContactAsignedInChatList({ status: true, contactId: chatContactAssigned.id }));
    } else {
      dispatch(getChats(true, true)).then(() => {
        dispatch(changeStatusContactAsignedInChatList({ status: true, contactId: contact.id }));
      });
    }
  }
}

export const changeStatusContactAsignedInChatList = (payload) => async (dispatch, getState) => {
  const { status, contactId } = payload;
  const { chatsList } = getState().Chat;
  let chatContactAssigned = chatsList.find(chat => chat.id === contactId);
  if (!chatContactAssigned) return;

  const newArrayChatsList = chatsList.map((chat) => {
    if (chat.id === chatContactAssigned.id) return { ...chatContactAssigned, contactAssigned: status };
    return chat;
  });
  dispatch(setChats(newArrayChatsList));
}

export const updateContactInChatsList = (contact) => (dispatch, getState) => {
  const chatsList = getState().Chat.chatsList;

  const newArrayChatsList = [...chatsList].map((currentContact) => {
    if (currentContact.id === contact.id) return contact;
    return currentContact;
  })

  dispatch(setChats(newArrayChatsList));
}

export const setSendImages = ({ value }) => ({
  type: SET_SEND_IMAGES,
  payload: value
});

export const setSendFile = (value) => ({
  type: SET_SEND_FILE,
  payload: value
});

export const setUnsentImages = ({ value }) => ({
  type: SET_UNSENT_IMAGES,
  payload: value
});

export const setMessageBoxLock = ({ status }) => ({
  type: SET_MESSAGE_BOX_LOCK,
  payload: status
});

export const setChatAgentFilter = (payload) => ({
  type: SET_CHAT_AGENT_FILTER,
  payload,
});

export const setPreuploadedMedia = (payload) => ({
  type: SET_PREUPLOADED_MEDIA,
  payload,
});

export const setQuickReplyMTemplate = (payload) => ({
  type: SET_QUICK_REPLY_MTEMPLATE,
  payload,
});

export const setListMessageMTemplate = (payload) => ({
  type: SET_LIST_MESSAGE_MTEMPLATE,
  payload,
});

export const getMatchedMessages = (
  firstPage = false,
) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const matchedMessages = getState().Chat.matchedMessages;
    const page = getState().Chat.searchPage;
    const searchQuery = getState().Chat.searchQuery;
    const searchTimeLapse = getState().Chat.searchTimeLapse;
    const { id: groupId } = currentGroup;
    const limit = 20;
    const offset = firstPage ? 0 : page * limit;

    if (firstPage) {
      dispatch(setMatchedMessages([]));
      dispatch(setSearchPage(0));
    }
    if (searchQuery.length >= 0 && searchQuery.length < 3) return;

    if (firstPage) dispatch(setSearchLoading(true));
    let pageMatchedMessages = await getFromMessageApi(`/messages/search?offset=${offset}&limit=${limit}&searchWords=${encodeURIComponent(searchQuery)}&timerange=${searchTimeLapse}`, {
      headers: { groupid: groupId },
    })
    dispatch(setSearchLoading(false));

    if (Array.isArray(pageMatchedMessages) && pageMatchedMessages.length > 0) {
      const newMatchedMessages = !firstPage ? [...matchedMessages, ...pageMatchedMessages] : pageMatchedMessages;
      const newPage = !firstPage ? page + 1 : 1;
      dispatch(setMatchedMessages(newMatchedMessages));
      dispatch(setSearchPage(newPage));
    }
  } catch (error) {
    setSearchLoading(false);
    console.log(error);
  }
};

export const setMatchedMessages = (messages) => ({
  type: SET_MATCHED_MESSAGES,
  payload: messages,
});

export const setSearchPage = (page) => ({
  type: SET_SEARCH_PAGE,
  payload: page,
});

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: query,
});

export const setSearchTimeLapse = (timeLapse) => ({
  type: SET_SEARCH_TIMELAPSE,
  payload: timeLapse,
});

export const setSearchLoading = (isLoading) => ({
  type: SET_SEARCH_LOADING,
  payload: isLoading,
});

export const resetChatSearch = () => ({
  type: RESET_SEARCH,
});

export const setPendingMessages = (contact) => async (dispatch, getState) => {
  try {
    const chatsList = getState().Chat.chatsList;
    const { id } = contact;

    let chatIdx = chatsList.findIndex(chat => chat._id === id);
    let newChatList = [...chatsList];
    const prevChat = newChatList[chatIdx].chat;

    if (prevChat && !prevChat.pendingMessages) {
      newChatList[chatIdx].chat = {
        ...prevChat,
        pendingMessages: 1,
      };
      await patch(`/contacts/${id}/chat`, {
        pendingMessages: 1,
      });
      dispatch(setChats(newChatList));
    }
  } catch (e) {
    console.log(e);
  }
}

const showNotification = (contact, message, typeFilterChat, dispatch) => {
  const { sender } = message;

  let formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  let transformBody = (message) => {
    if (message.content.type === "text") {
      return `${message.content.text.length > 14
        ? message.content.text.substring(0, 14) + "..."
        : message.content.text
        } - ${formatAMPM(new Date(message.updatedAt))}`;
    }
    if (message.content.type === "image") {
      return `📷 Foto - ${formatAMPM(new Date(message.updatedAt))}`;
    }
    if (message.content.type === "audio") {
      return `🎤 Mensaje de Voz - ${formatAMPM(
        new Date(message.updatedAt)
      )}`;
    }

    if (message.content.type === "file") {
      return `📁 Documento - ${formatAMPM(
        new Date(message.updatedAt)
      )}`;
    }

    if (message.content.type === "location") {
      return `📍 Ubicación en Mapa - ${formatAMPM(
        new Date(message.updatedAt)
      )}`;
    }
  };
  if (isIOS()) return;
  let notification = new Notification(`+${contact.countryCode ?? '51'} ${contact.phone}`, {
    icon: IconMantra,
    body: transformBody(message),
    silent: true,
  });

  let audio = new Audio(SoundNotificationWA);
  audio.play();

  notification.onclick = function (e) {
    e.preventDefault();
    window.focus();

    if (sender === "group" && typeFilterChat !== "default") {
      console.log("cambiar a group");

      dispatch(changeTypeFilterChats("group"));
    }

    if (sender === "contact" && typeFilterChat !== "default") {
      console.log("cambiar a contact");
      dispatch(changeTypeFilterChats("contact"));
    }

    if (
      sender !== "contact" &&
      sender !== "group" &&
      typeFilterChat !== "default"
    ) {
      console.log("cambiar a default");
      dispatch(changeTypeFilterChats("default"));
    }

    notification.close();
  };
}

export const setMessagePositionInChat = (position) => ({
  type: SET_MESSAGE_POSITION_CHAT,
  payload: position,
})

export const getOlderMessages = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const activeContact = getState().Contact.activeContact;
    const { currentChat, chatsCache, messagePositionChat } = getState().Chat;
    const LIMIT = 10;

    const response = await getFromMessageApi(`/messages/${activeContact._id}?offset=${messagePositionChat + currentChat.length - 1}&limit=${LIMIT}`, {
      headers: { GroupId: currentGroup._id },
    });

    if (Array.isArray(response) && response.length > 0) {
      dispatch(setCurrentChat([...response, ...currentChat]));

      if (messagePositionChat === 1 && (currentChat.length === chatsCache[activeContact._id].length)) {
        dispatch(updateChatCache({ contactId: activeContact._id, messages: [...response, ...chatsCache[activeContact._id]] }));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getLatestMessages = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const activeContact = getState().Contact.activeContact;
    const { currentChat, messagePositionChat } = getState().Chat;
    const LIMIT = 10;

    const calculatedLimit = messagePositionChat - LIMIT <= 0 ? messagePositionChat - 1 : LIMIT;
    const response = await getFromMessageApi(`/messages/${activeContact._id}?offset=${messagePositionChat - calculatedLimit - 1}&limit=${calculatedLimit}`, {
      headers: { GroupId: currentGroup._id },
    });

    if (Array.isArray(response) && response.length > 0) {
      dispatch(setMessagePositionInChat(response[response.length - 1].positionInChat));
      dispatch(setCurrentChat([...currentChat, ...response]));
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteMessage = (message) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const response = await deleteToMessageApi(`/messages/${message.contactId}/${message._id}`, {
      headers: { GroupId: currentGroup._id },
    });

    const { chatsList, chatsCache, currentChat } = getState().Chat;
    const { activeContact } = getState().Contact;

    if (response?._id && response.contactId) {
      const foundChat = chatsList?.find((chat) => chat._id === response.contactId);

      const previousMessage = currentChat[currentChat.length - 2];
      let previousChat = {
        lastMessage: previousMessage?.content?.text,
        lastMessageId: previousMessage?._id,
        lastMessageType: previousMessage?.content?.type,
        lastMessageStatus: previousMessage?.status,
        lastSender: previousMessage?.sender,
        updateAt: previousMessage?.createdAt,
      }

      if (activeContact?._id === response.contactId && activeContact.chat?.lastMessageId === response._id) {
        if (previousMessage) {
          dispatch(setActiveContact({
            ...activeContact,
            chat: { ...activeContact.chat, ...previousChat }
          }));
        } else {
          const copyActiveContact = { ...activeContact };
          delete copyActiveContact.chat;
          dispatch(setActiveContact({ ...copyActiveContact }));
        }
      }

      if (foundChat?.chat?.lastMessageId === response._id) {
        if (previousMessage) {
          foundChat.chat = { ...foundChat.chat, ...previousChat };
          dispatch(setChats([...chatsList]));
        } else {
          dispatch(getChats(true, true));
        }
      }

      const foundCachedChat = chatsCache[response.contactId];
      if (foundCachedChat) {
        const newCachedChat = foundCachedChat.filter((message) => message._id !== response._id);
        dispatch(updateChatCache({
          contactId: response.contactId,
          messages: newCachedChat.length > 0 ? [...newCachedChat] : null,
        }));
      }

      if (currentChat[0].contactId === response.contactId && Array.isArray(currentChat) && currentChat.length > 0) {
        const newCurrentChat = currentChat.filter((message) => message._id !== response._id);
        dispatch(setCurrentChat([...newCurrentChat]));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const setChatWithFlag = (contact, flag, enable) => async (dispatch, getState) => {
  try {
    const actionName = (!!enable ? 'enable-flag' : 'disable-flag');
    const response = await create(`/contacts/${contact._id}/${actionName}`, {
      flag,
    });

    const filterByFlag = {
      importantChat: 'important',
      finishedChat: 'finished',
    };
    const resultField = (!!enable ? 'flag' : 'deletedFlag');
    if (response?.[resultField] === flag) {
      const contacts = getState().Chat.chatsList;
      const typeFilterChat = getState().Chat.typeFilter;

      const foundContact = contacts.find(_contact => _contact._id === contact._id);
      if (foundContact) {
        foundContact[flag] = !!enable;
        if (typeFilterChat === filterByFlag[flag])
          dispatch(getChats(true, true));
        else {
          dispatch(setChats([...contacts]));
        }
      } else if (typeFilterChat === filterByFlag[flag]) {
        dispatch(getChats(true, true));
      }
      const activeContact = getState().Contact.activeContact;
      if (activeContact?.id === contact._id) {
        dispatch(setActiveContact({
          ...activeContact,
          [flag]: !!enable,
        }));
      }
    };
  } catch (error) {
    console.log(error);
  }
};

export const createSticker = (stickerUrl) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    const response = await apiClient.post(`/stickers`,{
      stickerUrl: stickerUrl,
      groupId: currentGroupId
    });
    const afterGroupId = getState().Group.currentGroup?.id;
    if(currentGroupId === afterGroupId){
      dispatch(getStickers())
    }
    console.log(response)
  } catch (error) {
    console.log(error);
  }
}

export const setStickers = (stickers) => {
  return {
    type: SET_STICKERS,
    payload: stickers,
  };
};

export const getStickers = () => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    const response = await apiClient.get(`/stickers?groupId=${currentGroupId}`)
    const afterGroupId = getState().Group.currentGroup?.id;
    if(currentGroupId === afterGroupId){
      if (Array.isArray(response) && response.length > 0) {
        dispatch(setStickers(response));
      } else dispatch(setStickers([]));
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteSticker = (stickerId) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    await apiClient.remove(`/stickers/${stickerId}`)
    const afterGroupId = getState().Group.currentGroup?.id;
    if(currentGroupId === afterGroupId){
      dispatch(getStickers())
    }
  } catch (error) {
    console.log(error);
  }
}

export const sendMessageSticker = (stickerUrl) => async (dispatch, getState) => {
  try {
    const activeContact = getState().Contact.activeContact;
    const currentGroupId = getState().Group.currentGroup?.id;
    await apiClient.postToMessageApi("/messages", {
      contactId: activeContact.id,
      content: { text: "", media: stickerUrl, type: "sticker" },
    }, {
      headers: { GroupId: currentGroupId },
    });
  } catch (error) {
    console.log(error);
  }
}

export const sendStickerUrl = () => async (dispatch, getState) => {
  try {
    const {file} = getState().Chat;
    const currentGroupId = getState().Group.currentGroup?.id;
    let formData = new FormData();
    formData.append("file", file);

    const media = await apiClient.postToMessageApi("/storage/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        GroupId: currentGroupId ,
      },
    });
    if(media){
      dispatch(createSticker(media?.url))
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * @param {Object} payload 
 * @param {string} payload.messageId 
 * @param {string} payload.contactId
 * @param {'contact' | 'group'} payload.reactionSender 
 * @param {string} payload.reactionEmoji 
 * @param {string} [payload.reactionSentAt] 
 */
export const updateMessageOfReaction = (payload) => async (dispatch, getState) => {
  try {
    const { chatsCache, currentChat, chatsList } = getState().Chat;
    const activeContact = getState().Contact.activeContact;

    if (chatsCache[payload.contactId]) {
      const copyChatCache = [...chatsCache[payload.contactId]];
      const chatCacheIdx = copyChatCache.findIndex(m => m._id === payload.messageId);
      if (chatCacheIdx !== -1) {
        const prevReactions = Array.isArray(copyChatCache[chatCacheIdx].reactions) ? copyChatCache[chatCacheIdx].reactions : [];
        copyChatCache[chatCacheIdx].reactions = [...prevReactions, {
          sender: payload.reactionSender,
          emoji: payload.reactionEmoji,
          sentAt: payload.reactionSentAt,
        }];
        dispatch(
          updateChatCache({
            contactId: payload.contactId,
            messages: copyChatCache,
          })
        );
      }
    }

    // if (activeContact.id === payload.contactId) {
    //   const copyCurrentChat = [...currentChat];
    //   const currentChatIdx = copyCurrentChat.findIndex(m => m._id === payload.messageId);
    //   if (currentChatIdx !== -1) {
    //     const prevReactions = Array.isArray(copyCurrentChat[currentChatIdx].reactions) ? copyCurrentChat[currentChatIdx].reactions : [];
    //     copyCurrentChat[currentChatIdx].reactions = [...prevReactions, {
    //       sender: payload.reactionSender,
    //       emoji: payload.reactionEmoji,
    //       sentAt: payload.reactionSentAt,
    //     }];
    //     dispatch(setCurrentChat(copyCurrentChat));
    //   }
    // }

    const chatIdx = chatsList.findIndex((chat) => chat._id === payload.contactId);
    if (chatIdx !== -1) {
      const { chat: contactChat } = chatsList[chatIdx];
      chatsList[chatIdx] = {
        ...chatsList[chatIdx],
        chat: {
          ...contactChat,
          lastReactionSender: payload.reactionSender,
          lastReactionEmoji: payload.reactionEmoji,
          lastReactionSentAt: payload.reactionSentAt,
          lastReactionMessage: payload.reactionMessage,
        },
      };
      dispatch(setChats([...chatsList]));
    }
  } catch (e) {
    console.log('Error attaching reaction to message:', e.message);
  }
};

export const sendReaction = (messageId, emoji) => async (dispatch, getState) => {
  try {
    const activeContact = getState().Contact.activeContact;
    const currentGroupId = getState().Group.currentGroup?.id;
    await apiClient.postToMessageApi("/messages", {
      contactId: activeContact.id,
      content: { type: 'reaction', text: emoji, reactionMessageId: messageId },
    }, {
      headers: { GroupId: currentGroupId },
    });
  } catch (error) {
    console.log('Error sending reaction:', error.message);
  }
}
