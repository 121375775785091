export const getTranslation = (file, language, key) => {
    return file[language]?.[key] || key;
};

export const LeftSidebarMenuLanguages = {
  en: {
    Chats: "Chats",
    Contacts: "Contacts",
    Templates: "Templates",
    Flows: "Flows",
    WhatsAppFlows: "WhatsApp Flows",
    TemplatesMessages: "Answers",
    Campaigns: "Campaigns",
    Reports: "Reports",
    Profile: "Profile",
    Users: "Users",
    GroupSettings: "Settings",
    Groups: "Groups",
    Logout: "Logout",
    Settings: "Settings",
  },
  es: {
    Chats: "Chats",
    Contacts: "Contactos",
    Templates: "Plantillas",
    Flows: "Flujos",
    WhatsAppFlows: "WhatsApp Flows",
    TemplatesMessages: "Respuestas",
    Campaigns: "Campañas",
    Reports: "Reportes",
    Profile: "Perfil",
    Users: "Usuarios",
    GroupSettings: "Configuraciones",
    Groups: "Grupos",
    Logout: "Salir",
    Settings: "Ajustes",
  },
  pt: {
    Chats: "Conversas",
    Contacts: "Contatos",
    Templates: "Modelos",
    Flows: "Fluxos",
    WhatsAppFlows: "WhatsApp Flows",
    TemplatesMessages: "Respostas",
    Campaigns: "Campanhas",
    Reports: "Relatórios",
    Profile: "Perfil",
    Users: "Usuários",
    GroupSettings: "Configurações",
    Groups: "Grupos",
    Logout: "Sair",
    Settings: "Ajustes",
  },
};
