import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Button, Card, Media, Badge, CardBody, CardHeader, Alert, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import SimpleBar from "simplebar-react";
import { motion } from "framer-motion";
import CustomCollapse from "./CustomCollapse.jsx";
import Avatar from "./common/Avatar";
import { closeUserSidebar, openContactEditModal, saveTagContact, saveTagIdContact } from "../redux/actions";
import { useTranslation } from "react-i18next";
import avatar7 from "../assets/images/users/avatar-7.jpg";
import ModalAgentAssignment from "../../src/components/contacts/ModalAgentAssignment";

import { updateChatStatusSetByUser,unassignContactFromAgent, saveAgentAssociation } from "../redux/actions";
import { isPublisher } from "../helpers/checkRoles";
import ModalChatStatusTags from "./contacts/ModalChatStatusTags.jsx";
import ModalChangeChatStatusTag from "./contacts/ModalChangeChatStatusTag";

import ModalAssignHistory from "./contacts/ModalAssignHistory";

import {
  getGroupsByPhone,
  setActiveTab} from "../redux/actions"

import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getTranslation, UserProfileSidebarLanguages } from "../components/language";

function UserProfileSidebar(props) {
  const [isStatusE3ModalOpen, setIsStatusE3ModalOpen] = useState(false);
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [isArchiveE3ModalOpen, setIsArchiveE3ModalOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(false);
  const { t } = useTranslation();
  const membersInGroup = useSelector(state => state.Members.members);
  const currentChat = useSelector((state) => state.Chat.currentChat);
  const currentGroup = useSelector((state) => state.Group.currentGroup);
  const currentContact = useSelector((state) => state.Contact.activeContact);
  const userAuth = useSelector((state) => state.Auth.user);
  const tagsData = useSelector((state) => state.Tags.data);
  const isUserSidebarDefaultOpen = useSelector((state) => state.Auth.isUserSidebarDefaultOpen);
  const [agent, setAgent] = useState("");
  const [contactSelected, setContactSelected] = useState(null);
  const [modalAgentAssignment, setModalAgentAssignment] = useState(false);
  
  const [chatState, setChatState] = useState(0)
  const dispatch = useDispatch();

  const [showModalAssignHistory, setShowModalAssignHistory] = useState(false);
  const nroRowsArea = useSelector((state) => state.General.nroRowsArea);

  const levels = useSelector(state => state.Group.levels);
  const hasDataExt001 = useSelector(state => state.Group.currentGroup?.dataExt001Enabled);

  const tagsLvl = useSelector(state => state.Group.tagsLvl);
  const [defaultTagLvl, setDefaultTagLvl] = useState([]);

  const tagLvl = useSelector(state => state.Contact.tagLvl);
  const [isUpdate, setIsUpdate] = useState(false);

  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(UserProfileSidebarLanguages, language, key);

  const [timeDiff, setTimeDiff] = useState("");

  useEffect(() => {
    if (tagsLvl) {
      setIsUpdate(true);

      let newDefaultTagLvl = []
      if(levels.length){
        for(var i=0; i<levels.length; i++){
          newDefaultTagLvl.push({})
        }
      }

      if(tagLvl.tags){
        for(var i=0; i<tagLvl.tags.length; i++){
          const foundLevel = tagsLvl.find(t => t._id === tagLvl.tags[i].tagId)
          if(foundLevel){
            const foundIndex = levels.findIndex(level => level.type === foundLevel.type)
            if(newDefaultTagLvl[foundIndex])
              newDefaultTagLvl[foundIndex].defaultTag = {value: tagLvl.tags[i].tagId, label: foundLevel.name}
          }
        }
      }
      setDefaultTagLvl(newDefaultTagLvl)
      setIsUpdate(false);
    }
  }, [tagsLvl, tagLvl, levels, currentContact]);

  useEffect(() => {
    const calculateTimeDiff = () => {
      if (!currentContact?.lastContactResponseAt) return "";
      
      const lastResponse = new Date(currentContact.lastContactResponseAt);
      const now = new Date();
      const diffMs = now - lastResponse;
      
      const timeLimit = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const remainingMs = timeLimit - diffMs;
      
      if (remainingMs <= 0) {
        return {
          text: "Expirado",
          color: "danger",
          remainingMs: remainingMs
        };
      }
      
      const hours = Math.floor(remainingMs / (1000 * 60 * 60));
      const minutes = Math.floor((remainingMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingMs % (1000 * 60)) / 1000);
      
      let badgeColor = "success";
      if (hours < 1) {
        badgeColor = "danger";     // Red for last hour
      } else if (hours < 12) {
        badgeColor = "warning";    // Yellow for less than 12 hours
      }
      
      return {
        text: hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : 
              minutes > 0 ? `${minutes}m ${seconds}s` : 
              `${seconds}s`,
        color: badgeColor,
        remainingMs: remainingMs
      };
    };

    setTimeDiff(calculateTimeDiff());

    const interval = setInterval(() => {
      setTimeDiff(calculateTimeDiff());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentContact?.lastContactResponseAt]);

  const handleAdd = (selected) => {
    setIsUpdate(true);
    dispatch(saveTagIdContact(selected.value))
  }

  useEffect(() => {
    if (props.user?.chatStatusSetByUser) {
      setChatState(3);
      return;
    }
    if(currentChat){
      let messagesByContact = 0;
      for (const message of currentChat) {
        if (message.sender == 'contact') {
          messagesByContact++;
        } else if (message.sender == 'group' && !message.autoResponse && messagesByContact >= 1 && ["sent", "delivered", "read"].includes(message.status)) {
          setChatState(2);
          return;
        }
      }
      if (messagesByContact > 0) {
        setChatState(1);
        return;
      }
      setChatState(0);
    }
  }, [currentChat, props.user]);

  const setE3Status = () => {
    dispatch(updateChatStatusSetByUser());
  }
  
  const handleArchiveE3Status = () => {
    dispatch(updateChatStatusSetByUser(true));
  }

  const unassignContact = () => {
    dispatch(unassignContactFromAgent());
  }

  const toggleCollapse1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen3(false);
  };

  const toggleCollapse3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
  };

  const closeuserSidebar = () => {
    props.closeUserSidebar();
  };

  const { user } = props;

  const getUserAssociatedWith = (contact) => {
    if (contact.userTagId && membersInGroup) {
      let member = membersInGroup.find(
        (i) => i.tagId == contact.userTagId
      );
      if (!member
        && Array.isArray(contact.userTagIds)
        && contact.userTagIds.length > 0
        && contact.userTagIds[contact.userTagIds.length - 1].userTagId
        && contact.userTagIds[contact.userTagIds.length - 1].userTagId === contact.userTagId
        && contact.userTagIds[contact.userTagIds.length - 1].userId
      ) {
        member = membersInGroup.find(
          (m) => m.id === contact.userTagIds[contact.userTagIds.length - 1].userId
        );
      }
      return member ? `${member.name}` : "Agente no identificado";
    }
  };

  const handleAgentAssignmentModal = () => {
    setContactSelected(user);
    setModalAgentAssignment(true);
  };

  const toggleModalAgentAssignment = () => {
    setModalAgentAssignment(!modalAgentAssignment);
    setAgent("");
  };

  const saveAgentAssignment = () => {
    dispatch(saveAgentAssociation(contactSelected, agent));
    setModalAgentAssignment(false);
    setAgent('');
  };

  const handleModalAssignHistory = () => {
    setContactSelected(user);
    setShowModalAssignHistory(true);
  };

  const toggleModalAssignHistory = () => {
    setShowModalAssignHistory(!showModalAssignHistory);
  };

  const userIsPublisher = isPublisher(currentGroup.id, userAuth);

  const [showModalChatStatusTags, setShowModalChatStatusTags] = useState(false);
  const [showModalChangeChatStatusTag, setShowModalChangeChatStatusTag] = useState(false);

  const history = useHistory();

  const handleEditContact = async() => {
    history.push("/dashboard");
    await new Promise(resolve => setTimeout(resolve, 200));
    dispatch(setActiveTab("chats"));
    dispatch(openContactEditModal(true));
  }

  return (
    <motion.div
      className={`templates ${isUserSidebarDefaultOpen ? "p-0" : ""}`}
      style={
        !isUserSidebarDefaultOpen
          ? { height:`calc(100vh - 116px - ${21*(nroRowsArea-1)}px)`, minHeight:`calc(100vh - 116px - ${21*(nroRowsArea-1)}px)`, zIndex: "1" }
          : { zIndex: "3" }
      }
      id="templates-sidebar"
      animate={props.userSidebar ? "open" : "close"}
      initial={{ opacity: 0, display: "none" }}
      variants={{
        open: {
          x: 0,
          opacity: 1,
          display: "block",
        },
        close: {
          x: "100%",
          display: "none",
        },
      }}
    >
      <React.Fragment>
      <ModalAssignHistory
        show={showModalAssignHistory}
        toggle={toggleModalAssignHistory}
        contactSelected={contactSelected}
        membersOfGroup={membersInGroup}
      />
      <Modal isOpen={isStatusE3ModalOpen} toggle={() => { setIsStatusE3ModalOpen(!isStatusE3ModalOpen) }} centered={true}>
        <ModalHeader toggle={() => { setIsStatusE3ModalOpen(!isStatusE3ModalOpen) }}>Cambiar estado del chat</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas marcar el chat como { currentGroup.chatStatusSetByUserLabel ?? 'éxito' }?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { setE3Status(); setIsStatusE3ModalOpen(!isStatusE3ModalOpen); }}>Continuar</Button>
          <Button color="secondary" onClick={() => { setIsStatusE3ModalOpen(!isStatusE3ModalOpen) }}>Cancelar</Button>
        </ModalFooter>
      </Modal>
      
      <Modal isOpen={isUnassignModalOpen} toggle={() => { setIsUnassignModalOpen(!isUnassignModalOpen) }} centered={true}>
        <ModalHeader toggle={() => { setIsUnassignModalOpen(!isUnassignModalOpen) }}>Liberar chat</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas liberar el chat?<br/>
          Esto hará visible el contacto/chat a otros agentes.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { unassignContact(); setIsUnassignModalOpen(!isUnassignModalOpen); }}>Continuar</Button>
          <Button color="secondary" onClick={() => { setIsUnassignModalOpen(!isUnassignModalOpen) }}>Cancelar</Button>
        </ModalFooter>
      </Modal>

        <Modal isOpen={isArchiveE3ModalOpen} toggle={() => { setIsArchiveE3ModalOpen(!isArchiveE3ModalOpen) }} centered={true}>
          <ModalHeader toggle={() => { setIsArchiveE3ModalOpen(!isArchiveE3ModalOpen) }}>Archivar estado { currentGroup.chatStatusSetByUserLabel ?? 'éxito' }</ModalHeader>
          <ModalBody>
            ¿Estás seguro de que deseas archivar el estado actual { currentGroup.chatStatusSetByUserLabel ?? 'éxito' }?<br/>
            El chat regresará al estado <Badge color="warning">En proceso</Badge>.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { handleArchiveE3Status(); setIsArchiveE3ModalOpen(!isArchiveE3ModalOpen); }}>Continuar</Button>
            <Button color="secondary" onClick={() => { setIsArchiveE3ModalOpen(!isArchiveE3ModalOpen) }}>Cancelar</Button>
          </ModalFooter>
        </Modal>

        <div className="h-100">
          {!isUserSidebarDefaultOpen && (
            <div className="px-3 px-lg-4 pt-3 pt-lg-4">
              <div className="user-chat-nav text-right">
                <Button
                  color="none"
                  type="button"
                  onClick={closeuserSidebar}
                  className="nav-btn"
                  id="user-profile-hide"
                >
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            </div>
          )}
          <SimpleBar
            className={`${isUserSidebarDefaultOpen ? "h-100" : "px-4 pt-4"} user-profile-desc`}
            style={isUserSidebarDefaultOpen ? { padding: "2rem" } : { maxHeight: "100%", paddingBottom: '130px' }}
          >
            {isUserSidebarDefaultOpen && <h4 class="mb-4">Información de contacto</h4>}
            <div id="profile-user-accordion" className="custom-accordion">
              {currentGroup.hasNotifications && currentContact?.lastContactResponseAt && (
                <div className="mb-3 d-flex align-items-center">
                  {timeDiff.remainingMs > 0 ? (
                    <p className="text-muted mr-1 m-0">{translate("Chat expires in")}</p>
                  ) : (
                    <span style={{ color: 'red' }}>
                      {translate("Chat has expired")}
                    </span>
                  )}
                  {timeDiff.remainingMs > 0 && (
                    <h5 className="font-size-16 m-0">
                      <Badge color={timeDiff.color || "success"}>
                        {timeDiff.text || ""}
                      </Badge>
                    </h5>
                  )}
                </div>
              )}
              <Card className="shadow-none border mb-2">
                {/* import collaps */}
                <CustomCollapse
                  title={translate("Contact Data")}
                  iconClass="ri-user-2-line"
                  isOpen={isOpen1}
                  toggleCollapse={toggleCollapse1}
                >
                  <div>
                    <p className="text-muted mb-1">{translate("Name")}</p>
                    <h5 className="font-size-14">{user?.name}</h5>
                  </div>

                  {user?.email && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Email")}</p>
                      <h5 className="font-size-14">{user?.email}</h5>
                    </div>
                  )}

                  <div className="mt-4">
                    <p className="text-muted mb-1">{translate("Phone")}</p>
                    <h5 className="font-size-14">{user?.phone}</h5>
                  </div>

                  {user?.brand && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Brand")}</p>
                      <h5 className="font-size-14">{user?.brand}</h5>
                    </div>
                  )}

                  {user?.custom_1 && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Extra Field")}</p>
                      {
                        (typeof(user.custom_1) === "string" && user.custom_1.startsWith("http")) ?
                        <a target='_blank' href={user.custom_1}>{user.custom_1}</a> :
                        <h5 className="font-size-14">{user.custom_1}</h5>
                      }
                    </div>
                  )}

                  {user?.custom_2 && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Extra Field")}</p>
                      {
                        (typeof(user.custom_2) === "string" && user.custom_2.startsWith("http")) ?
                        <a target='_blank' href={user.custom_2}>{user.custom_2}</a> :
                        <h5 className="font-size-14">{user.custom_2}</h5>
                      }
                    </div>
                  )}

                  {user?.custom_3 && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Extra Field")}</p>
                      {
                        (typeof(user.custom_3) === "string" && user.custom_3.startsWith("http")) ?
                        <a target='_blank' href={user.custom_3}>{user.custom_3}</a> :
                        <h5 className="font-size-14">{user.custom_3}</h5>
                      }
                    </div>
                  )}

                  {user?.custom_4 && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Extra Field")}</p>
                      {
                        (typeof(user.custom_4) === "string" && user.custom_4.startsWith("http")) ?
                        <a target='_blank' href={user.custom_4}>{user.custom_4}</a> :
                        <h5 className="font-size-14">{user.custom_4}</h5>
                      }
                    </div>
                  )}

                  {user && !isNaN(user.source) && (
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Registration origin")}</p>
                      <h5 className="font-size-14">
                        {(() => {
                          switch (user.source) {
                            case 0:
                              return 'Mantra API';
                            case 1:  
                              return (
                                <>
                                  <Badge className="font-size-12" color="success">
                                    <i className="ri-whatsapp-line"></i>
                                  </Badge>
                                  {' '}
                                  {'Whatsapp'}
                                </>
                              );
                            case 2:
                              return 'Mantra Web App';
                            case 3:
                              return (
                                <>
                                {user.sourceInputId && Array.isArray(currentGroup.sourceInputs) ? (() => {
                                  const sourceInputData = currentGroup.sourceInputs.find(i => i.source == 3 && i.sourceInputId == user.sourceInputId);
                                  return sourceInputData ? `${sourceInputData.name}` : translate("Mantra Integration");
                                })() : translate("Mantra Integration")}
                                </>
                              );
                            default:
                              return '-';
                          }
                        })()}
                      </h5>
                    </div>
                  )}

                  {Array.isArray(currentContact?.tagIdList) && currentContact.tagIdList.length > 0 ?(
                    <div className="mt-4">
                      <p className="text-muted mb-1">{translate("Tags")}</p>
                      {
                        currentContact.tagIdList.map((item, key) => 
                        <small className="badge badge-soft-primary font-size-14 mr-1 mb-1" key={key}>
                          {tagsData.find(opt => opt?.id === item)?.name}</small>)
                      }
                    </div>
                  ):""}

                  {
                    levels.map((level, id)=>
                      <div className="mt-4" key={id}>
                      <p className="text-muted mb-1">{`${translate("Chat tag")} ${level.name}`}</p>
                      <Select
                        onChange={(selected) => handleAdd(selected)}
                        value={defaultTagLvl[id]?.defaultTag?defaultTagLvl[id].defaultTag:null}
                        options={tagsLvl?.filter(tag => tag.type === level.type).map(tag => {return {value: tag._id, label: tag.name}})}
                        isDisabled={isUpdate}
                        className="basic-multi-select"
                        placeholder="Seleccione"
                      />
                      </div>
                    )
                  }

                  {/*hasDataExt001 && <div className="mt-4">
                    <p className="text-muted mb-1">{"Etiqueta de chat Nivel 1"}</p>
                    <Select
                      onChange={(selected) => handleSave("1", selected)}
                      value={defaultTagLvl1}
                      options={tagsLvl1}
                      isDisabled={isUpdate}
                      name="nivel1"
                      className="basic-multi-select"
                      placeholder="Seleccione"
                    />
                  </div>*/}

                  {/*hasDataExt001 && <div className="mt-4">
                    <p className="text-muted mb-1">{"Etiqueta de chat Nivel 2"}</p>
                    <Select
                      onChange={(selected) => handleSave("2", selected)}
                      value={defaultTagLvl2}
                      options={tagsLvl2}
                      isDisabled={isUpdate}
                      name="nivel2"
                      className="basic-multi-select"
                      placeholder="Seleccione"
                    />
                  </div>*/}

                  {/*hasDataExt001 && <div className="mt-4">
                    <p className="text-muted mb-1">{"Etiqueta de chat Nivel 3"}</p>
                    <Select
                      onChange={(selected) => handleSave("3", selected)}
                      value={defaultTagLvl3}
                      options={tagsLvl3}
                      isDisabled={isUpdate}
                      name="nivel3"
                      className="basic-multi-select"
                      placeholder="Seleccione"
                    />
                  </div>*/}

                  <Button color="primary" size="sm" className="float-right mt-3 mb-3" onClick={handleEditContact}>
                    {translate("Edit Contact")}
                  </Button>
                </CustomCollapse>
              </Card>

              <Card className="shadow-none border mb-2">
                <CustomCollapse
                  title={translate("Assigned Agent Data")}
                  iconClass="ri-user-settings-line"
                  isOpen={isOpen2}
                  toggleCollapse={() => setIsOpen2(!isOpen2)}
                >
                  <div>
                    <p className="text-muted mb-1">{translate("Assigned agent")}</p>
                    <h5 className="font-size-14">
                      {user?.userTagId ? getUserAssociatedWith(user) : translate("No agent assigned")}
                      {(user?.userTagId && Array.isArray(user?.userTagIds) && user.userTagIds.length > 0) ? (
                        <small className="float-right">
                          {`${translate("From")} ${new Date(user.userTagIds[user.userTagIds.length-1]?.assignedAt).toLocaleDateString('en-gb')}`}
                        </small>
                      ) : ("")}
                    </h5>                  
                  </div>
                  {(!userIsPublisher || user?.userTagId) && (
                    <div className="clearfix mt-3">
                      <UncontrolledDropdown size="sm" className="float-right mt-1">
                        <DropdownToggle color="primary" caret>
                          {translate("Actions")}
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={handleAgentAssignmentModal}>
                            {translate("Assign new agent")}
                          </DropdownItem>
                          {user?.userTagId && (
                            <DropdownItem onClick={() => { setIsUnassignModalOpen(!isStatusE3ModalOpen) }}>
                              {translate("Release")}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <Button color="link" size="sm" className="float-right mt-2" onClick={handleModalAssignHistory}>
                        {translate("View assignment history")}
                      </Button>
                    </div>
                  )}
                </CustomCollapse>
              </Card>

              {props.activeUser?.isGroup === true && (
                <Card className="mb-1 shadow-none border">
                  <CustomCollapse
                    title="Members"
                    iconClass="ri-group-line"
                    isOpen={isOpen3}
                    toggleCollapse={toggleCollapse3}
                  >
                    <Card className="p-2 mb-2">
                      <Media className="align-items-center">
                        <div className="chat-user-img align-self-center mr-3">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                              S
                            </span>
                          </div>
                        </div>
                        <Media body>
                          <div className="text-left">
                            <h5 className="font-size-14 mb-1">
                              {t("Sara Muller")}
                              <Badge
                                color="danger"
                                className="badge-soft-danger float-right"
                              >
                                {t("Admin")}
                              </Badge>
                            </h5>
                          </div>
                        </Media>
                      </Media>
                    </Card>

                    <Card className="p-2 mb-2">
                      <Media className="align-items-center">
                        <div className="chat-user-img align-self-center mr-3">
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                              O
                            </span>
                          </div>
                        </div>
                        <Media body>
                          <div className="text-left">
                            <h5 className="font-size-14 mb-1">
                              {t("Ossie Wilson")}
                            </h5>
                          </div>
                        </Media>
                      </Media>
                    </Card>

                    <Card className="p-2 mb-2">
                      <Media className="align-items-center">
                        <div className="chat-avatar">
                          <img
                            src={avatar7}
                            className="rounded-circle chat-user-img avatar-xs mr-3"
                            alt="chatvia"
                          />
                        </div>
                        <Media body>
                          <div className="text-left">
                            <h5 className="font-size-14 mb-1">
                              {t("Paul Haynes")}
                            </h5>
                          </div>
                        </Media>
                      </Media>
                    </Card>
                  </CustomCollapse>
                </Card>
              )}
            </div>

            <Card className="shadow-none border mb-2">
              <CardHeader>{translate("Chat Status")}</CardHeader>
              
              <CardBody>
                <div>
                  <Alert color="danger" style={{opacity: chatState === 1 ? "1" : "0.3"}} className="d-flex align-items-center">
                    { chatState !== 1 && <i className="ri-checkbox-blank-circle-line"></i> }
                    { chatState === 1 && <i className="ri-checkbox-blank-circle-fill"></i> }
                    <span className="ml-2">{translate("Pending")}</span>
                  </Alert>
                </div>
                <div>
                  <Alert color="warning" style={{opacity: chatState === 2 ? "1" : "0.3"}} className="d-flex align-items-center">
                    { chatState !== 2 && <i className="ri-checkbox-blank-circle-line"></i> }
                    { chatState === 2 && <i className="ri-checkbox-blank-circle-fill"></i> }
                    <span className="ml-2">{translate("In progress")}</span>
                  </Alert>
                </div>
                <div>
                  <Alert color="success" style={{opacity: chatState === 3 ? "1" : "0.3"}} className="d-flex align-items-center">
                    { chatState !== 3 && <i className="ri-checkbox-blank-circle-line"></i> }
                    { chatState === 3 && <i className="ri-checkbox-blank-circle-fill"></i> }
                    <span className="ml-2 flex-grow-1">{ currentGroup.chatStatusSetByUserLabel ?? translate("Success") }</span>
                    { currentContact?.chatStatusSetByUser && (
                      <small>
                        <abbr title={`${translate("Last record on")} ${new Date(currentContact?.chatStatusSetByUserAt).toLocaleString('en-gb')}`}>
                          {new Date(currentContact?.chatStatusSetByUserAt).toLocaleDateString('en-gb')}
                        </abbr>
                      </small>
                    ) }
                  </Alert>
                </div>
                {
                  chatState === 2 && !!currentContact?.userTagId && userIsPublisher && (
                    <div>
                      <Button color="success" onClick={() => { setIsStatusE3ModalOpen(!isStatusE3ModalOpen) }}>
                        {`${translate("Mark as")} ${currentGroup.chatStatusSetByUserLabel ?? translate("Success")}`}
                      </Button>
                    </div>
                  )
                }
                {
                  chatState === 3 && !!currentContact?.userTagId && userIsPublisher && (
                    <div>
                      <Button color="danger" size="sm" onClick={() => { setIsUnassignModalOpen(!isStatusE3ModalOpen) }}>
                        {translate("Release chat")}
                      </Button>
                      <Button color="warning" size="sm" className="float-right" onClick={() => { setIsArchiveE3ModalOpen(!isArchiveE3ModalOpen) }}>
                        {`${translate("Archive")} ${currentGroup.chatStatusSetByUserLabel ?? translate("Success")}`}
                      </Button>
                    </div>
                  )
                }
              </CardBody>
            </Card>

            <Card className="shadow-none border mb-2">
              <CardHeader id="profile-user-headingOne">
                <h5 className="font-size-14 m-0">
                  {translate("Chat status tags")}
                </h5>
              </CardHeader>
              <CardBody>
                <div>
                  <p className="text-muted mb-1">{translate("Current tags")}</p>
                  <h5 className="font-size-14">
                    {Array.isArray(currentContact?.chatStatusTags) && currentContact.chatStatusTags.length > 0 ? (
                      <>
                        {currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1]?.tags?.map((item,key)=>
                          <small className="badge badge-soft-primary font-size-14 mr-1 mb-1" key={key}>{item}</small>)}
                        {<small className="badge badge-soft-primary font-size-14 mr-1 mb-1">
                            {currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1]?.tag}
                          </small>}
                        <div className="d-flex pt-2 justify-content-end">
                          <p className="m-0">
                            {`${translate("From")} ${new Date(currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1].assignedAt).toLocaleDateString('en-gb')}`}
                          </p>
                        </div>
                      </>
                    ) : (
                      '-'
                    )}
                  </h5>
                  <div className="mt-3">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => setShowModalChatStatusTags(true)}
                    >
                      {translate("View history")}
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      className="float-right"
                      onClick={() => setShowModalChangeChatStatusTag(true)}
                    >
                      {translate("Change tags")}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </SimpleBar>
          <ModalAgentAssignment
            saveAgentAssignment={saveAgentAssignment}
            modal={modalAgentAssignment}
            agent={agent}
            setAgent={setAgent}
            contactSelected={contactSelected}
            membersOfGroup={membersInGroup}
            toggleAgentAssignment={toggleModalAgentAssignment}
          />
          <ModalChatStatusTags
            show={showModalChatStatusTags}
            toggle={() => setShowModalChatStatusTags(false)}
            currentContact={currentContact}
          >
          </ModalChatStatusTags>
          <ModalChangeChatStatusTag
            show={showModalChangeChatStatusTag}
            toggle={() => setShowModalChangeChatStatusTag(false)}
            currentGroup={currentGroup}
            currentContact={currentContact}
          >
          </ModalChangeChatStatusTag>
        </div>
      </React.Fragment>
    </motion.div>
  );
}

const mapStateToProps = (state) => {
  const { users, active_user } = state.Chat;
  const { userSidebar } = state.Layout;
  return { users, active_user, userSidebar };
};

export default connect(mapStateToProps, { closeUserSidebar })(
  UserProfileSidebar
);
