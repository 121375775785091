import React from "react";
import { Collapse, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isPublisher } from "../helpers/checkRoles";

function CustomCollapse(props) {
  const { isOpen, toggleCollapse, isTemplate, isPin, isPublisher, handlePinnedTemplate, templateId } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Link to="#" onClick={toggleCollapse} className="text-dark">
        <CardHeader id="profile-user-headingOne" style={{ backgroundColor: isTemplate?"#F9FAFC":"" }}>
          <h5 className="d-flex justify-content-between align-items-center font-size-14 m-0">
            {props.iconClass && (
              <i
                className={
                  props.iconClass + " mr-2 align-middle d-inline-block"
                }
              ></i>
            )}
            <div>
              {isTemplate && (isPin || !isPublisher) && <i
                className={
                  `mdi ${isPin ? "mdi-pin" : "mdi-pin-outline"} accor-plus-icon`
                }
                style={{
                  border: "solid 1px #C6C6C6", 
                  borderRadius: "3px", 
                  padding: "4px 8px",
                  marginRight: "8px",
                }}
                onMouseOver={(e) => {
                  if(!isPublisher)
                    e.target.style.backgroundColor = "#A9A9A9";
                }}
                onMouseOut={(e) => {
                  if(!isPublisher)
                    e.target.style.backgroundColor = "transparent";
                }}
                onClick={(e) => {
                  if(!isPublisher)
                    handlePinnedTemplate(templateId, !isPin);
                }}
              ></i>}
              {props.title}
            </div>
            <i
              className={
                isOpen
                  ? "ml-1 mdi mdi-chevron-up float-right accor-plus-icon"
                  : "ml-1 mdi mdi-chevron-down float-right accor-plus-icon"
              }
              style={{ fontSize: "1.5rem" }}
            ></i>
          </h5>
        </CardHeader>
      </Link>

      <Collapse isOpen={isOpen}>
        <CardBody>{props.children}</CardBody>
      </Collapse>
    </React.Fragment>
  );
}

export default CustomCollapse;
