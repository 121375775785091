import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import {
  Nav,
  NavItem,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  logoutUser,
  closeTemplatesSidebar,
  closeUserSidebar,
  readNotifications,
} from "../../redux/actions";
import { isAdmin, isSuperAdmin, isPublisher } from "../../helpers/checkRoles";

//Import Components
import Avatar from "../../components/common/Avatar";

//Import Images
import logo from "../../assets/images/icon-mantra.png";

//i18n
import i18n from "../../i18n";

import { getTranslation, LeftSidebarMenuLanguages } from "./language";

function LeftSidebarMenu(props) {
  const { user } = useSelector((state) => state.Auth);
  const idCurrentGroup = useSelector((state) => state.Group.currentGroup._id);
  const currentGroup = useSelector((state) => state.Group.currentGroup);
  const totalNotifications = useSelector(
    (state) => state.Notifications.totalNotifications
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [currentScreenWidth, setCurrentScreenWidth] = useState(
    window.innerWidth
  );
  const [lng, setlng] = useState("English");

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = props.activeTab;

  /* changes language according to clicked language menu item */
  const changeLanguageAction = (lng) => {
    /* set the selected language to i18n */
    i18n.changeLanguage(lng);

    if (lng === "sp") setlng("Spanish");
    else if (lng === "gr") setlng("German");
    else if (lng === "rs") setlng("Russian");
    else if (lng === "it") setlng("Italian");
    else if (lng === "eng") setlng("English");
  };

  const [checkAdmin, setCheckAdmin] = useState(false);
  const [checkSuperAdmin, setCheckSuperAdmin] = useState(false);
  const [checkPublisher, setCheckPublisher] = useState(false);

  useEffect(() => {
    if (user && idCurrentGroup) {
      const _isAdmin = isAdmin(idCurrentGroup, user);
      const _isSuperAdmin = isSuperAdmin(idCurrentGroup, user);
      setCheckAdmin(_isAdmin);
      setCheckSuperAdmin(_isSuperAdmin);
      const _isPublisher = isPublisher(idCurrentGroup, user);
      setCheckPublisher(_isPublisher);
    }
  }, [idCurrentGroup]);

  useEffect(() => {
    function handleResize() {
      setCurrentScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const language = useSelector((state) => state.Group.language);
  const translate = (key) =>
    getTranslation(LeftSidebarMenuLanguages, language, key);

  return (
    <React.Fragment>
      <div className='side-menu flex-lg-column mr-lg-1 side_menu__scroll'>
        {/* LOGO */}
        <div
          className='navbar-brand-box'
          style={{ backgroundColor: "#10c999" }}
        >
          <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logo} alt='logo' height='30' />
            </span>
          </Link>

          {/* <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="logo" height="30" />
            </span>
          </Link> */}
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className='flex-lg-column my-auto'>
          <Nav
            pills
            className='side-menu-nav justify-content-center'
            role='tablist'
          >
            <NavItem id='Chats'>
              <Link
                to='/dashboard'
                id='pills-chat-tab'
                className={classnames("nav-link", {
                  active: activeTab === "chat",
                })}
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className='ri-message-3-line'></i>
              </Link>
            </NavItem>
            <UncontrolledTooltip target='Chats' placement='top'>
              {translate("Chats")}
            </UncontrolledTooltip>

            {currentGroup.hasNotifications && (
              <>
                <NavItem id='Notifications'>
                  <Link
                    to='/dashboard/notifications'
                    id='pills-chat-tab'
                    className={classnames("nav-link", {
                      active: activeTab === "notifications",
                    })}
                    onClick={() => {
                      toggleTab("notifications");
                      dispatch(readNotifications(idCurrentGroup));
                    }}
                  >
                    {totalNotifications > 0 ? (
                      <div className='position-relative'>
                        <i className='ri-notification-2-line'></i>
                        <span
                          className='badge badge-pill badge-danger position-absolute'
                          style={{
                            top: "0px",
                            right: "0px",
                            fontSize: "10px",
                            padding: "4px 6px",
                          }}
                        >
                          {totalNotifications}
                        </span>
                      </div>
                    ) : (
                      <i className='ri-notification-2-line'></i>
                    )}
                  </Link>
                </NavItem>
                <UncontrolledTooltip target='Notifications' placement='top'>
                  {translate("Notifications")}
                </UncontrolledTooltip>
              </>
            )}

            <NavItem id='Contacts'>
              <NavLink
                to='/dashboard/contacts'
                id='pills-contacts-tab'
                className={classnames("nav-link", {
                  active: activeTab === "contacts",
                })}
                onClick={() => {
                  toggleTab("contacts");
                }}
              >
                <i className='ri-contacts-line'></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target='Contacts' placement='top'>
              {translate("Contacts")}
            </UncontrolledTooltip>

            {currentScreenWidth >= 992 &&
              (!checkPublisher ||
                !currentGroup.templateRestrictionForPublisher) && (
                <>
                  <NavItem id='Templates'>
                    <NavLink
                      to='/dashboard/templates'
                      id='pills-templates-tab'
                      className={classnames("nav-link", {
                        active: activeTab === "templates",
                      })}
                      onClick={() => {
                        dispatch(closeTemplatesSidebar());
                        dispatch(closeUserSidebar());
                        toggleTab("templates");
                      }}
                    >
                      T{/* <i className="ri-contacts-line"></i> */}
                    </NavLink>
                  </NavItem>
                  <UncontrolledTooltip target='Templates' placement='top'>
                    {translate("Templates")}
                  </UncontrolledTooltip>
                </>
              )}

            {currentScreenWidth >= 992 && currentGroup?.hasWsFlows &&
              (!checkPublisher || !currentGroup.templateRestrictionForPublisher) && (
              <>
                <NavItem id="WhatsAppsFlows">
                  <NavLink
                    to="/dashboard/whatsapp-flows"
                    id="pills-whatsapp-flows-tab"
                    className={classnames("nav-link", {
                      active: activeTab === "whatsAppFlows",
                    })}
                    onClick={() => {                      
                      dispatch(closeTemplatesSidebar());
                      dispatch(closeUserSidebar());
                      toggleTab("whatsAppFlows");
                    }}
                  >
                    F{/* <i className="ri-contacts-line"></i> */}
                  </NavLink>
                </NavItem>
                <UncontrolledTooltip target="WhatsAppsFlows" placement="top">
                  {translate("WhatsAppFlows")}
                </UncontrolledTooltip>
              </>
            )}

            {currentScreenWidth >= 992 &&
              currentGroup?.hasFlows &&
              (!checkPublisher ||
                !currentGroup.flowRestrictionForPublisher) && (
                <>
                  <NavItem id='Flows'>
                    <NavLink
                      to='/dashboard/flows'
                      id='pills-contacts-tab'
                      className={classnames("nav-link", {
                        active: activeTab === "flows",
                      })}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        toggleTab("flows");
                      }}
                    >
                      <div className='d-flex justify-content-center align-items-center font-weight-bold'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='26'
                          viewBox='0 0 11 10'
                          fill='currentColor'
                        >
                          <path d='M4.93.422c-.535.277-.692.902-.348 1.383.102.14.258.289.348.324.125.055.156.11.156.266v.203l-1.676.02c-1.594.015-1.695.023-1.941.144-.434.21-.66.425-.848.797l-.18.347v2.117c0 2.399.016 2.5.446 2.965.136.149.386.328.543.403l.297.129h7.472l.281-.133c.375-.184.641-.422.825-.754.148-.27.148-.281.168-2.383.011-1.371 0-2.215-.043-2.402-.102-.426-.461-.828-.942-1.051l-.398-.192H5.84v-.199c0-.16.035-.219.21-.324.466-.258.618-.965.298-1.383C6.039.301 5.398.18 4.93.422zm.695.55a.68.68 0 0 1 .152.18c.063.114.055.157-.05.29-.172.214-.434.19-.57-.047-.153-.25.218-.579.468-.422zm3.371 2.333c.3.074.649.394.734.675.032.118.063.422.063.68v.457l-.91.844-.91.851V8.91H2.949V6.812L1.13 5.11v-.515c0-.422.027-.567.144-.774a.971.971 0 0 1 .68-.523c.332-.067 6.762-.067 7.043.008zM2.2 7.969c0 .718-.02.941-.078.941-.226-.004-.621-.242-.785-.469-.176-.242-.176-.246-.191-1.316l-.02-1.078.531.492.543.492zm7.54.195c-.122.379-.403.613-.837.715l-.175.031V7.031l.523-.48.512-.473.02.938c.011.543-.005 1.027-.044 1.148zm0 0' />
                          <path d='M3.352 4.66c-.356.168-.497.559-.313.89.27.473 1.004.462 1.254-.019.129-.242.129-.34.008-.578-.164-.316-.602-.457-.95-.293zm3.617.016a.787.787 0 0 0-.34.308c-.352.606.512 1.204 1.086.758.285-.222.336-.57.129-.851-.133-.18-.39-.32-.586-.32-.04 0-.168.046-.29.105zM3.766 6.715c-.149.137-.157.219-.024.39.094.13.113.13 1.715.13 1.762 0 1.781 0 1.781-.333 0-.289-.097-.304-1.789-.304-1.476 0-1.562.004-1.683.117zm.015.937c-.14.164-.144.328-.015.45.129.117.183.117 1.746.105l1.613-.02.082-.16c.074-.14.07-.18-.012-.308l-.101-.137H5.469c-1.223 0-1.64.016-1.688.07zm0 0' />
                        </svg>
                      </div>
                    </NavLink>
                  </NavItem>
                  <UncontrolledTooltip target='Flows' placement='top'>
                    {translate("Flows")}
                  </UncontrolledTooltip>
                </>
              )}

            <NavItem id='TemplatesMessages'>
              <NavLink
                to='/dashboard/templates-messages'
                id='pills-templates-messages-tab'
                className={classnames("nav-link", {
                  active: activeTab === "templatesMessages",
                })}
                onClick={() => {
                  toggleTab("templatesMessages");
                }}
              >
                <i className='ri-question-answer-line'></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target='TemplatesMessages' placement='top'>
              {translate("TemplatesMessages")}
            </UncontrolledTooltip>

            {(!checkPublisher ||
              !currentGroup.campaignRestrictionForPublisher) && (
              <>
                <UncontrolledTooltip target='campaigns' placement='top'>
                  {translate("Campaigns")}
                </UncontrolledTooltip>
                <NavItem id='campaigns'>
                  <NavLink
                    to='/dashboard/campaigns'
                    id='pills-campaigns-tab'
                    className={classnames("nav-link", {
                      active: activeTab === "campaigns",
                    })}
                    onClick={() => {
                      toggleTab("campaigns");
                    }}
                  >
                    C
                  </NavLink>
                </NavItem>
              </>
            )}

            {/*
            <NavItem id="Segments">
              <NavLink
                to="/dashboard/segments"
                id="pills-templates-messages-tab"
                className={classnames("nav-link", {
                  active: activeTab === "segments",
                })}
                onClick={() => {
                  toggleTab("segments");
                }}
              >
                C
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Segments" placement="top">
              Campaigns
            </UncontrolledTooltip>
            */}

            {checkAdmin && (
              <>
                {/* REPORTS */}
                {currentScreenWidth >= 992 && (
                  <>
                    <UncontrolledTooltip target='Reports' placement='top'>
                      {translate("Reports")}
                    </UncontrolledTooltip>
                    <NavItem id='Reports'>
                      <NavLink
                        to='/dashboard/reports'
                        id='pills-reports-tab'
                        className={classnames("nav-link", {
                          active: activeTab === "reports",
                        })}
                        onClick={() => {
                          dispatch(closeTemplatesSidebar());
                          dispatch(closeUserSidebar());
                          toggleTab("reports");
                        }}
                      >
                        <i className='ri-pie-chart-line'></i>
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </>
            )}

            {checkAdmin && (
              <>
                <UncontrolledTooltip target='users' placement='top'>
                  {translate("Users")}
                </UncontrolledTooltip>
                <NavItem id='users'>
                  <NavLink
                    to='/dashboard/users'
                    onClick={() => toggleTab("users")}
                    className='nav-link'
                  >
                    <i className='ri-team-line align-middle'></i>
                  </NavLink>
                </NavItem>
              </>
            )}
            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className='profile-user-dropdown d-inline-block d-lg-none mb-4'
              style={{ flexGrow: "1.5" }}
            >
              <DropdownToggle
                className='d-flex justify-content-center align-items-center'
                nav
                style={{ backgroundColor: "transparent" }}
              >
                <div className='d-flex justify-content-center'>
                  <Avatar nameLetter={user?.name?.charAt(0)?.toUpperCase()} />
                </div>
              </DropdownToggle>
              <DropdownMenu className='w-100 mb-15'>
                <NavLink
                  to='/dashboard/profile'
                  className='dropdown-item'
                  style={{
                    display: "flex",
                    gap: "8px",
                    padding: "22px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#7a7f9a",
                  }}
                  onClick={() => {
                    toggleTab("profile");
                    toggleMobile();
                  }}
                >
                  <i className='ri-profile-line'></i>
                  {translate("Profile")}
                </NavLink>
                <DropdownItem divider />
                <NavLink
                  to='/dashboard/groups'
                  className='dropdown-item'
                  style={{
                    display: "flex",
                    gap: "8px",
                    padding: "22px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#7a7f9a",
                  }}
                  onClick={() => {
                    toggleTab("group");
                    toggleMobile();
                  }}
                >
                  <i className='ri-group-line float-right text-muted'></i>
                  {translate("Groups")}
                </NavLink>

                <NavLink
                  to='/dashboard/settings'
                  className='dropdown-item'
                  onClick={() => {
                    dispatch(closeTemplatesSidebar());
                    dispatch(closeUserSidebar());
                    toggleTab("settings");
                    toggleMobile();
                  }}
                  style={{
                    display: "flex",
                    gap: "8px",
                    padding: "22px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "#7a7f9a",
                  }}
                >
                  <i className='ri-settings-2-line text-muted'></i>
                  {translate("Settings")}
                </NavLink>
                <DropdownItem divider />
                <DropdownItem onClick={() => dispatch(logoutUser(history))}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      color: "#7a7f9a",
                    }}
                  >
                    <i className='ri-logout-circle-r-line float-right text-muted'></i>
                    {translate("Logout")}
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}
        <Dropdown
          nav
          isOpen={dropdownOpen}
          className='btn-group dropup profile-user-dropdown'
          toggle={toggle}
          direction='right'
        >
          <DropdownToggle nav>
            <div className='d-flex justify-content-center'>
              <Avatar nameLetter={user?.name?.charAt(0)?.toUpperCase()} />
            </div>
          </DropdownToggle>
          <DropdownMenu
            style={{
              position: "fixed",
              left: "75px",
              bottom: "0px",
              zIndex: 1050,
              transform: "none",
            }}
          >
            <NavLink
              to='/dashboard/profile'
              className='dropdown-item'
              style={{
                display: "flex",
                gap: "8px",
                padding: "22px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#7a7f9a",
              }}
              onClick={() => {
                toggleTab("profile");
                toggle();
              }}
            >
              <i className='ri-profile-line'></i>
              {translate("Profile")}
            </NavLink>
            <DropdownItem divider />
            <NavLink
              to='/dashboard/groups'
              className='dropdown-item'
              style={{
                display: "flex",
                gap: "8px",
                padding: "22px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#7a7f9a",
              }}
              onClick={() => {
                toggleTab("group");
                toggle();
              }}
            >
              <i className='ri-group-line float-right text-muted'></i>
              {translate("Groups")}
            </NavLink>
            <NavLink
              to='/dashboard/settings'
              className='dropdown-item'
              style={{
                display: "flex",
                gap: "8px",
                padding: "22px",
                paddingTop: "10px",
                paddingBottom: "10px",
                color: "#7a7f9a",
              }}
              onClick={() => {
                dispatch(closeTemplatesSidebar());
                dispatch(closeUserSidebar());
                toggleTab("settings");
                toggle();
              }}
            >
              <i className='ri-settings-2-line text-muted'></i>
              {translate("Settings")}
            </NavLink>
            <DropdownItem divider />
            <DropdownItem onClick={() => dispatch(logoutUser(history))}>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  color: "#7a7f9a",
                }}
              >
                <i className='ri-logout-circle-r-line float-right text-muted'></i>
                {translate("Logout")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
